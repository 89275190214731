import Vue from 'vue';
import { AxiosError, AxiosResponse } from 'axios';

import * as Mutations from "@/store/common/mutations.type"

import { Tag } from './types';

type GetTagsParams = {
  client_id: string;
  module: string;
  tmpl: string;
}

// Получение тегов для клиента и указанного модуля, возможно по подстроке
export const getTags = async ({commit, rootState}: any, data: GetTagsParams) => { 
  const tmpl = data.tmpl ? `?tmpl=${data.tmpl}` : ''
  const response:AxiosResponse = await Vue.axios.get(`api/tag/${data.client_id}/${data.module}${tmpl}`)
  // список тегов для раздела
  commit('common/'+Mutations.SET_ITEMS, { 
    state: rootState.tags,
    data: response.data
  }, {root: true})
}

// создать тег, аналогично получению - для клиента и модуля
// Созданный тег надо добавить в массив тегов его раздела
export const createTag = async ({commit, rootState}: any, data: Tag) => { 
  const response:AxiosResponse = await Vue.axios.post(`api/tag`, data)
  // список тегов для раздела
  commit('common/'+Mutations.ADD_ITEM, { 
    state: rootState.tags,
    data: response.data.result
  }, {root: true})
  return response.data.result // возвращаем чтобы как-то обработать после вызова, например добавить новый тег в список в статье
}

// Изменение тега, цвета скорей всего, т.к. название менять нельзя
export const saveTag = async ({commit, rootState}: any, data: Tag) => { 
  const response:AxiosResponse = await Vue.axios.put(`api/tags/${data._id}`, data)
  // список тегов для раздела
  commit('common/'+Mutations.UPD_ITEM, { 
    state: rootState.tags,
    data: response.data
  }, {root: true})
  return response.data
}

type AttachTagReq = {
  _id?: string;  // если не указано, то будет создан новый
  client_id?: string; 
  module?: string;   // модуль тега если это еще не существующий
  title?: string;   // текст тега если это еще не существующий
  item_id: string;
  item_type: string;
}

// Прикрепление тега к элементу, например статье
export const attachTag = async ({commit, rootState}: any, data: AttachTagReq) => { 
  const response:AxiosResponse = await Vue.axios.put(`api/tag/attach/to/${data.item_type}/${data.item_id}`, data)
  // т.к тип может быть разным и хранилище разное, просто возвращаем список тегов объекта, чтобы получить обновленный список для объекта
  // затем объект надо сохранить с этим списком
  return response.data
}

export const detachTag = async ({commit, rootState}: any, data: AttachTagReq) => {
  const response:AxiosResponse = await Vue.axios.put(`api/tag/detach/${data._id}/from/${data.item_type}/${data.item_id}`)
  return response.data
}

export const deleteTag = async ({commit, rootState}: any, data: {tag_id: string, client_id: string}) => {
  const response:AxiosResponse = await Vue.axios.delete(`api/tag/${data.client_id}/${data.tag_id}`)
  commit('common/'+Mutations.REMOVE_ITEM, { 
    state: rootState.tags,
    data: {_id: data.tag_id}
  }, {root: true})
  return response.data
}
