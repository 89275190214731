<template>
  <a-modal
    :title="$t('common.file_loading')"
    :closable="false"
    :mask-closable="false"
    :visible="progressShow"
    centered
    :width="250"
    :z-index="9999"
    :body-style="{textAlign: 'center'}"
    :footer="null"
  >
    <a-progress type="circle" :percent="progress" />
  </a-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
// import {
//   SET_PROGRESS,
//   // SET_PROGRESS_VISIBLE,
// } from '@/store/common/mutations.type'

export default {
  name: "LoadingProgress",
  computed: {
    ...mapGetters(['progress','progressShow'])
  },
  // methods: {
  //   ...mapMutations([SET_PROGRESS])
  // },
  watch: {
    // progressShow(val) {
    //   if (val == true) this.SET_PROGRESS(0)
    // }
  }
}
</script>

<style>

</style>