import Vue from 'vue';
import { AxiosError, AxiosResponse } from 'axios';

import * as Mutations from './mutations.type';
import { LinksBlock, BlockLink } from './types';

// получение всех блоков у клиента
export const getBlocks = async ({commit}: any, client_id: string) => {
  const response:AxiosResponse<LinksBlock[]> = await Vue.axios.get(`api/links_block/${client_id}`)
  commit(Mutations.SET_BLOCKS, response.data)
}

// получение блока для раздела
export const getBlock = async ({commit}: any, data: {client_id: string, module: string}) => {
  const response:AxiosResponse<LinksBlock> = await Vue.axios.get(`api/links_block/${data.client_id}/${data.module}`)
  commit(Mutations.SET_BLOCK, response.data)
}

// Переключение активности блока
export const toggleBlock = async ({commit}: any, data: {client_id: string, module: string}) => {
  const response:AxiosResponse<LinksBlock> = await Vue.axios.put(`api/links_block/toggle`, data)
  commit(Mutations.TOGGLE_BLOCK, response.data)
}

// Добавление ссылки в блок 
export const addLink = async ({commit}: any, data: {block_id: string, link: BlockLink}) => {
  const response:AxiosResponse<LinksBlock> = await Vue.axios.post(`api/links_block/${data.block_id}/link`, data.link)
  commit(Mutations.SET_BLOCK, response.data)
}

// Изменение ссылки в блоке
export const editLink = async ({commit}: any, data: {block_id: string, link: BlockLink}) => {
  const response:AxiosResponse<LinksBlock> = await Vue.axios.put(`api/links_block/${data.block_id}/link`, data.link)
  commit(Mutations.SET_BLOCK, response.data)
}

// Удаление ссылки в блоке
export const delLink = async ({commit}: any, data: {block_id: string, link: BlockLink}) => {
  const response:AxiosResponse<LinksBlock> = await Vue.axios.delete(`api/links_block/${data.block_id}/link/${data.link._id}`)
  commit(Mutations.SET_BLOCK, response.data)
}