import Vue from 'vue';
import { AxiosResponse, AxiosError } from 'axios';
import { SET_ITEM, SET_ITEMS, ADD_ITEM, UPD_ITEM, REMOVE_ITEM, UPD_LIST_ITEM } from './mutations.type';

// объект для запроса всех объектов некоторого модуля для определенного клиента
type CommonReq = {
  module: string,
  client_id: string,
  _id?: string, // опционально для запроса одного элемента
}

// запрос для изменения одного объекта
type CommonNewOrChangeReq = {
  module: string,  // имя модуля, например post
  _id?: string,  // код объекта, который меняем, если нет то это создание
  field?: string, // поле в хранилище, если это не стандартное item
  data: any,  // данные, которые надо записать
}

// Запрос для удаления одного объекта
type CommonDelReq = {
  module: string,
  _id: string, // может быть массив сюда добавить и в заголовок его через запятую?
}

// универсальный ответ с сервера
type CommonResponse = {
  result?: any | any[],
  error?: any,
  status: number,
}

// TODO: надо отказаться от этого заголовка т.к. код клиента зашит в токене.
// Вопрос только для суперадмина, надо на бэке проверять не только код клиента но и права суперадмина
export function headers(data) {
  return { 
    headers: {
      'X-COMPANY-ID': data.client_id,
    }
  }
}

// Получение всех объектов для отдельного клиента (передается в заголовке X-COMPANY-ID)
export const getModuleItems = async ({commit, rootState}: any, data: CommonReq) => {
  try {
    const resp: AxiosResponse<CommonResponse> = await Vue.axios
      .get(`api/${data.module}`)
    commit(SET_ITEMS, { state: rootState[data.module], data: resp?.data?.result})
    return resp?.data?.result
  } catch (error) {
    const err = error as AxiosError
    return (err.response?.data as any)?.message || err.message || 'Unknown error'
  }
}

// Получение одного объекта
export const getModuleItem = async ({commit, rootState}: any, data: CommonReq) => {
  try {
    const resp: AxiosResponse<CommonResponse> = await Vue.axios
      .get(`api/${data.module}/${data._id}`, headers(data))
    commit(SET_ITEM, { state: rootState[data.module], data: resp.data?.result})
    return resp.data?.result
  } catch (error) {
    const err = error as AxiosError
    return (err.response?.data as any)?.message || err.message || 'Unknown error'
  }
}

// Создание одного объекта
export const newModuleItem = async ({commit, rootState}: any, data: CommonNewOrChangeReq) => {
  try {
    const resp: AxiosResponse<CommonResponse> = await Vue.axios
      .post(`api/${data.module}`, data.data, headers(data))
    commit(ADD_ITEM, { state: rootState[data.module], data: resp.data?.result})
    return resp.data?.result
  } catch (error) {
    const err = error as AxiosError
    throw (err.response?.data as any)?.error?.message || err.message || 'Unknown error'
  }
}

// Обновление одного объекта
export const editModuleItem = async ({commit, rootState}: any, data: CommonNewOrChangeReq) => {
  try {
    const resp: AxiosResponse<CommonResponse> = await Vue.axios
      .put(`api/${data.module}/${data._id}`, data.data, headers(data))
    commit(UPD_LIST_ITEM, { state: rootState[data.module], data: resp.data?.result})  // обновляем в списке
    // если в сторе заполнено поле объекта, то его обновляем
    if (rootState[data.module][data.field || 'item']) commit(UPD_ITEM, { 
      state: rootState[data.module],
      field: data.field,
      data: resp.data?.result
    })
    return resp.data?.result
  } catch (error) {
    const err = error as AxiosError
    return (err.response?.data as any)?.message || err.message || 'Unknown error'
  }
}

// Удаление одного объекта
export const delModuleItem = async ({commit, rootState}: any, data: CommonDelReq) => {
  try {
    const resp: AxiosResponse<CommonResponse> = await Vue.axios
      .delete(`api/${data.module}/${data._id}`, headers(data))
    commit(REMOVE_ITEM, { state: rootState[data.module], _id: data._id})
    return resp.data?.result
  } catch (error) {
    const err = error as AxiosError
    return (err.response?.data as any)?.message || err.message || 'Unknown error'
  }
}