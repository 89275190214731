import { Adaptation, AdaptationTrack, Step, TrackMemberData } from './types'

import * as Service from "./service"
import * as Actions from "./actions.type"
import { SET_TRACK_USERS, ADD_TRACK_USER, SET_TRACK_SCENARIO, SET_TRACK_STEP, SET_TRACK } from "./mutations.type"

interface State {
  list: AdaptationTrack[], // список досок для начальной страницы
  item: AdaptationTrack | null,  // активная доска для ее страницы 
  activeTrack: AdaptationTrack | null, // открытая в окне редактирования задача
  adaptationSettings: Adaptation | null,
  users_on_adaptation: any | null,
  // track_members: TrackMemberData[] | null, // участники трека
  user_activity: any | null, // пользовательское прохождение трека
}


// STORE 
const store: State = {
  list: [], // единый реквизит для списка объектов из общего модуля CRUD (common)
  item: null, // аналогично для активного объекта
  activeTrack: null,
  adaptationSettings: null,
  users_on_adaptation: null,
  user_activity: null,
}

/**
 * А что если из service файла возвращать сразу объекты в виде {
 *  action: ADD_USER,
 *  do: addUser,
 * }
 * тогда тут не надо импортировать ничего кроме сервиса и мутаций, а объект actions автоматически заполнять в цикле
 * получится всегда одна строка везде типа такой: 
 * const actions = Object.entries(services).reduce((acc, item) => ({...acc, [item[0]]: item[1]}), {})
 *  */ 

const actions = {
  [Actions.ADD_USER_TO_TRACK]: Service.addUserToTrack,
  [Actions.ADD_STAGE]: Service.addStage,
  [Actions.EDIT_STAGE]: Service.editStage,
  [Actions.DELETE_STAGE]: Service.delStage,
  [Actions.ADD_STEP]: Service.addStep,
  [Actions.EDIT_STEP]: Service.editStep,
  [Actions.DELETE_STEP]: Service.delStep,
  [Actions.GET_TRACK_MEMBERS]: Service.getTrackMembers, // просто все участники трека
  [Actions.GET_ADAPTATIONS]: Service.getAdaptations, // новички для ментора/руководителя
  [Actions.GET_USER_TRACK]: Service.getUserTrack, // трек самого новичка
  [Actions.EDIT_USER_TRACK]: Service.editUserTrack, // изменение участников трека
  [Actions.GET_USER_TRACK_FOR]: Service.getUserTrackAsViewer, // трек новичка
  [Actions.COPY_TRACK]: Service.copyTrack, // копируем трек
  [Actions.FINISH_STEP]: Service.finishStep,
  [Actions.UNFINISH_STEP]: Service.unfinishStep,
  [Actions.RESUME_STEP]: Service.resumeStep,
  [Actions.DEL_USER_STEP]: Service.delUserStep,
  [Actions.GET_SETTINGS]: Service.getSettings,
  [Actions.SAVE_SETTINGS]: Service.saveSettings,
  [Actions.DEL_ADAPTATION]: Service.delAdaptation,
  [Actions.DEL_TRACK]: Service.delTrack,
}


// изменение задач внутри активной доски
const mutations = {
  [ADD_TRACK_USER](state: State, data: any) {
    if (!state.item) return
    if (!state.item.users?.length) state.item = {...state.item, users: [data]}
    else state.item = {...state.item, users: [...state.item.users, data]}
  },
  [SET_TRACK_USERS](state: State, data: any) {
    if (state.item) state.item = {...state.item, users: data}
  },
  [SET_TRACK](state: State, data: any) {
    state.item = data
  },
  [SET_TRACK_SCENARIO](state: State, data: {scenario: string, chain: any[]}) {
    if (state.item) state.item[data.scenario] = data.chain
  },
  [SET_TRACK_STEP](state: State, data: {track: string, scenario: string, stage: string, step: Step}) {
    if (!state.item) return
    const stage = state.item[data.scenario].find(st => st._id == data.stage) // ищем этап в нужном сценарии
    // замещаем шаг в этапе
    stage.steps = stage.steps.map(step => step._id == data.step._id ? data.step : step)
  },
}

export default {
  namespaced: true,
  state: store,
  // getters,
  actions,
  mutations,
};