import Vue from "vue/types/umd";
import {VNode} from 'vue'

import {$d} from './plugins/day'

// Передача VNode в content вместо текста:
// h => <div domPropsInnerHTML={someTextVal}></div>

type BtnType = 'default' | 'primary' | 'ghost' | 'dashed' | 'danger' | 'link'

export function $error($vm:Vue, _title:string, _content:string, err: string) {
	const title: string = $vm.$t(_title) as string || _title
	const content: string = $vm.$t(_content, {err}) as string || _content
	$vm.$error({
		title,
		content,
		centered: true,
	})
	return false
}

export function $success($vm:Vue, _title:string, _content:string, params: any) {
	const title: string = $vm.$t(_title) as string || _title
	const content: string = $vm.$t(_content, params) as string || _content
	$vm.$success({
		title,
		content,
		centered: true,
	})
	return false
}

export function $notif(
	$vm:Vue, 
	type: 'info' | 'error' | 'warning' | 'success' | 'warn', 
	_message: string, 
	_description: string | VNode,
	placement: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' = 'topLeft')
{
	const message: string = $vm.$t(_message) as string || _message
	const description: string | VNode = typeof _description === 'string' ? 
		$vm.$t(_description) as string : 
		_description
	$vm.$notification[type]({
		message,
		description: h => h('span', { domProps: {
      innerHTML: description
    }}),
		placement
	});
	return type == 'error'
}

export function $message(
	$vm:Vue, 
	type: 'info' | 'error' | 'warning' | 'success' | 'warn' | 'loading', 
	_message: string,
	_params: any = {}) 
{
	$vm.$message[type]($vm.$t(_message, _params) as string)
	return false
}

export function $confirm(
	$vm:Vue,
	title: string,
	params: {
		okText?: string,
		okType?: BtnType,
		cancelText?: string,
		content: string | VNode,
		onOk: () => any | undefined
	}
) 
{
	$vm.$confirm({
		centered: true,
		title: $vm.$t(title),
		content: typeof params.content == 'string' ? $vm.$t(params.content) : params.content,
		okText: $vm.$t(params.okText || 'common.confirm') as string,
		okType: params.okType || 'primary',
		cancelText: $vm.$t(params.cancelText || 'common.cancel') as string,
		onOk: params.onOk,
	})
}

// MongoDB-like _id string
export function ObjectId(m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) {
  return s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))
}

//#region Files
export function download(VM, url, name) {
	VM.axios({
		url,
		method: 'GET',
		responseType: 'blob',
	}).then(response => {
		var fileURL = window.URL.createObjectURL(new Blob([response.data]))
		var fileLink = document.createElement('a')

		fileLink.href = fileURL
		const fileName = name || decodeURI(response.headers['content-disposition'].split('=').pop())
		fileLink.setAttribute('download', fileName)
		document.body.appendChild(fileLink)

		fileLink.click()
	}).catch(e => {
		if (e.response) {
			if (e.response.status === 404) $message(VM, 'error', 'common.errors.file_not_found')
		} else {
			$error(VM, 'common.error', 'common.errors.file_dwn', e.message)
		}
	})
}

export function fBytes(n: number) {
	if (!n) return 0
	if (n < 1024) return `${n} B`
	if (n < 1024**2) return `${(n/1024).toFixed(1)} kB`
	if (n < 1024**3) return `${(n/1024**2).toFixed(1)} MB`
	if (n < 1024**4) return `${(n/1024**3).toFixed(1)} GB`
	if (n < 1024**5) return `${(n/1024**4).toFixed(1)} TB`
}

//#endregion

// create closure function
export function sortBy(a, b, field) {
	return (a,b) => a[field] == b[field] ? 0 : (a[field] < b[field] ? 1 : -1)
}

export function sort(arr, field, direction = 1) {
	return [...arr].sort((a,b) => 
		a[field] == b[field] ? 0 : (a[field] < b[field] ? -direction : direction)
	)
}

// Добавляем прозрачность к цвету -> #ccffaa -> rgba(85, 75, 123, 0.4)
export function hexWithAlpha(hex, alpha) {
  // Убираем символ # из HEX значения
  hex = hex.replace(/^#/, '');

  // Разбиваем HEX на составляющие: R, G, B
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Возвращаем массив чисел
  return `rgb(${r}, ${g}, ${b}, ${alpha})`;
}

// Из массива повторяющихся строк создает объект с подсчетом повторений
export function groupValues(values: string[]) {
	return values.reduce((acc, i) => ({...acc, [i]: acc[i] ? acc[i]+1 : 1}) , {})
}

// убирание смещения часового пояса через dayjs
export function utcDate(d: Date) {
	const o = $d().utcOffset()
	return $d(d).utc(true)
}

// убирание смещения часового пояса через Date
export function fromUTC(d: string | number) {
	const off = new Date().getTimezoneOffset()
	return new Date(new Date(d).getTime() + off*60*1000)
}