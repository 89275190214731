<template>
  <div class="page col w-100 h-100" :class="noPad ? 'p0' : ($mq == 'phone' ? 'p4' : 'py4 px8')">
    <PageHeader v-if="header" :header="header" :back="back">
      <slot v-if="$slots.header" name="header" />
    </PageHeader>

    <slot />

  </div>
</template>

<script>
export default {
  components: {
    PageHeader: () => import('./PageHeader'),
  },

  props: {
    header: String,
    noPad: Boolean,
    back: Boolean,
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/styles/vars'

.page
  position relative
  background-color var(--bg-color) // $bg-blue
  &.p0
    padding 0

  @media (max-width: $phone) {
    margin: 0;
    padding: 16px;
  }
  // @media (min-width: $phone + 1px)
  //   margin 20px 20px 0 20px
  // @media (min-width: $tablet + 1px)
  //   margin 10px 30px 50px 30px
</style>