export const SET = 'SET'
export const SET_PROGRESS = 'SET_PROGRESS'
export const SET_PROGRESS_VISIBLE = 'SET_PROGRESS_VISIBLE'
export const SET_FEED = 'SET_FEED'
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU'

// общие мутации для любых типовых объектов
export const SET_ITEMS = 'SET_ITEMS'
export const SET_ITEM = 'SET_ITEM'
export const ADD_ITEM = 'ADD_ITEM'
export const UPD_ITEM = 'UPD_ITEM'  // обновление активного элемента (на странице объекта)
export const UPD_LIST_ITEM = 'UPD_LIST_ITEM' // обновление элемента из списка объектов
export const REMOVE_ITEM = 'REMOVE_ITEM'