export const GET_SERVICES = 'GET_SERVICES'
export const GET_BUSINESS_PROCESSES = 'GET_BUSINESS_PROCESSES'  // получение шаблонов процессов
export const CONVERT_BP = 'CONVERT_BP'  // конвертация старых сервисов в шаблоны процессов

export const GET_BP = 'GET_BP'
export const CREATE_BP = 'CREATE_BP'
export const CHANGE_BP = 'CHANGE_BP'
export const DELETE_BP = 'DELETE_BP'

// шаги отдельно для упрощения и уменьшения объема запросов
export const CREATE_STEP = 'CREATE_STEP' 
export const CHANGE_STEP = 'CHANGE_STEP'
export const DELETE_STEP = 'DELETE_STEP'
export const STEP_PASS = 'STEP_PASS'

export const GET_PROCESSES = 'GET_PROCESSES'  // получение запущенных процессов
export const GET_SERVICE_GROUPS = 'GET_SERVICE_GROUPS'
export const CREATE_SERVICE_GROUP = 'CREATE_SERVICE_GROUP'
export const CHANGE_SERVICE_GROUP = 'CHANGE_SERVICE_GROUP'
export const DELETE_SERVICE_GROUP = 'DELETE_SERVICE_GROUP'
