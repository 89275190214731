// ФУНКЦИИ ОБЩЕГО НАЗНАЧЕНИЯ ДЛЯ НЕСКОЛЬКИХ РАЗДЕЛОВ 

// определение наличия прав на раздел для событий разных типов
export function hasAccess(access, area) {
	if (['GROUPS', 'GROUPS_NEW', 'GROUP_DELETED', 'INVITES'].includes(area)) return access.groups
	return true // дефолтное значение
}
/**
 * Функции хелперы: сортировка массивов по дате в убывающем порядке
 */
export function compare(a, b) {
	if (a.created < b.created) return 1
	if (a.created > b.created) return -1
	return 0
}

// TODO: i18n
