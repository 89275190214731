import { State } from '../post.module'

type Like = {
  _id?: string,
  created?: string,
  author: {
    firstName: string,
    lastName: string,
    _id: string,
  },
  emoji: string,
}

type LikeResponse = {
  likes: Like[],
  where: string,
  post_id: string,
}

type LikeCommentResponse = {
  post_id: string,
  comment_id: string,
  _id: string,
  created: string,
  author: any,
  where: string,
}

type postViewedResp = {
  post_id: string,
  where: string,
  view: {
    user: string,
    time: Date,
    _id: string
  }
}

type AnswerResp = {
  _id: string,
  type: string,
  post_id: string,
  parent: {
    id: string,
    postId: string,
    type: string,
    where: string,
  },
  where?: string,
}

// определение в откуда в сторе брать посты - лента компании, юзера или просто посты (в группе или профиле)
const getFeed = (state, where: string) => {
  // объяснение: если явно указан ключ company или feed то берем эти ленты
  // Если ключа нет то берем ленту постов (она заполнена когда мы в группе или в профиле)
  // если постов нет, значит лайкнули пост профиля или группы, не находясь в них, 
  // а значит из ленты пользователя - значит берем снова ленту
  return {
    company: state.company_feed,
    feed: state.user_feed,
    user: state.user_feed.length ? state.user_feed : state.posts,
  }[where] || (state.posts.length && state.posts) || state.user_feed
}

const findPost = (state, where, post_id) => {
  const post = state.currentPost 
    ? state.currentPost 
    : getFeed(state, where).find(p => p._id === post_id)
  if (!post) console.log('post not found!', where, post_id)
  return post
}

// добавление поста
export function addPost(state: State, post: any) {
  const feed = getFeed(state, post.parent.type)
  state.posts = [post, ...state.posts];
  feed.unshift({...post, $t: 'post'}) // в начале ленты добавляем пост
  feed.pop() // с конца убираем один чтоб количество осталось тем же
}

export function changePost(state: State, post: any) {
  // const feed = getFeed(state, post.parent.type)
  let p = findPost(state, post.parent.type, post._id)
  // if (feed) {
  //   const index = feed.findIndex(({ _id }) => _id === post._id);
  //   feed.splice(index, 1, {...post, $t: 'post'})
  // } else {
  //   state.currentPost = post;
  // }
  Object.assign(p, {...post, $t: 'post'})
}

export function changeComment(state: State, comment: any) {
  const p = findPost(state, comment.parent.where, comment.parent.id)
  // const cmnt = p.comments.find((c: any) => c._id === comment._id)
  p.comments = p.comments.map(c => c._id === comment._id ? comment : c)
}

export function changeAnswer(state: State, answer: any) {
  const p = findPost(state, answer.parent.where, answer.parent.postId)
  const comment = p.comments.find((c: any) => c._id === answer.parent.id);
  const ai = comment.answers.findIndex((answ: any) => answ._id === answer._id);
  comment.answers.splice(ai, 1, answer)
}

export function removePost(state: State, data: {where: string, post_id: string}) {
  const feed = getFeed(state, data.where)
  if (feed) {
    const index = feed.findIndex(({ _id }) => _id === data.post_id);
    feed.splice(index, 1)
  } else {
    // delete and redirect to back
  }
}

export function setPostViewed(state: State, data: postViewedResp) {
  const feed = getFeed(state, data.where)
  const post = feed.find(p => p._id == data.post_id)
  const viewed = post.views.some(v => v.user == data.view.user)
  if (!viewed) post.views = [...post.views, data.view]    
}

// добавление комментария к посту
export function addCommentToPost(state: State, comment: any) {
  const post = findPost(state, comment.parent.where, comment.parent.id)
  post.comments = [comment, ...post.comments]
}

export function removeComment(state: State, comment: any) {
  const post = findPost(state, comment.parent.where, comment.parent.id)
  const commentIndex = post.comments.findIndex((c: any) => c._id === comment._id);
  post.comments.splice(commentIndex, 1)
}

// добавление ответа к комментарию
export function addAnswerToComment(state: State, answer: any) {
  const post = findPost(state, answer.parent.where, answer.parent.postId)
  const comment = post.comments.find((c: any) => c._id === answer.parent.id);
  comment.answers = [answer, ...comment.answers]
}

export function removeAnswer(state: State, answer: AnswerResp) {
  const post = findPost(state, answer.parent.where, answer.post_id)
  const comment = post.comments.find((c: any) => c._id === answer.parent.id);
  const answerIndex = comment.answers.findIndex((answ: any) => answ._id === answer._id);
  comment.answers.splice(answerIndex, 1)
}

// добавление лайка к посту
export function addLikeToPost(state: State, like: LikeResponse) {
  const post = findPost(state, like.where, like.post_id)
  // const feed = getFeed(state, like.where)
  // const post = feed?.find((post) => post._id === like.post_id);
  post.likes = like.likes
}
// добавление лайка к комментарию
export function addLikeToComment(state: State, like: LikeCommentResponse) {
  const post = findPost(state, like.where, like.post_id)
  // const feed = getFeed(state, like.where)
  // const post = state.posts ? state.posts.find((post) => post._id === like.post_id) : state.currentPost;
  const comment = post.comments.find((comment: any) => comment._id === like.comment_id)
  comment.likes.push({
    _id: like._id,
    created: like.created,
    author: like.author
  })
}
// добавление лайка к ответу на комментарий
export function addLikeToAnswer(state: State, like: LikeCommentResponse) {
  const post = findPost(state, like.where, like.post_id)
  const comment = post.comments.find((c: any) => c._id == like.comment_id) 
  const answer = comment.answers.find((a: any) => answer._id == like.comment_id)
  answer.likes.push({
    _id: like._id,
    created: like.created,
    author: like.author
  })
}