<template>
  <a-dropdown v-if="eventList.length">
    <div v-if="$mq != 'phone'" class="panel-menu" >
      {{$t('cal.events_today')}}: <b>{{eventList.length}}</b> <a-icon type="down" />
      <!-- <a-button @click="() => beep && beep.play()" size="small">Play</a-button> -->
    </div>
    <a-badge :count="eventList.length" show-zero v-else>
      <a-button icon="calendar" shape="circle" style="margin-right: 10px" />
    </a-badge>
    
    <a-menu slot="overlay">
      <a-menu-item v-for="ev in eventList" :key="ev._id">
        <div class="col" @click="goEvent(ev)">
          <div class="f-align-center">
            <div class="event-time">{{ev.time}}</div>
            <div class="event-name ml2">{{ev.name}}</div>
          </div>
          <div class="event-remain" :class="timeClass(ev.minutes)">
            {{$t('cal.time_left')}}: {{ev.remain}}
          </div>
        </div>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  GET_EVENTS,
  GET_EVENTS_BY_CLIENT,
} from "@/store/user/actions.type";
import mxCoreData from '@/mixins/userData'

export default {
  name: "EventNotifier",
  data: () => ({
    beep: null,
    eventList: [],
    played: new Set
  }),
  
	mixins: [ mxCoreData ],
  
  computed: {
    ...mapGetters(['events','uid']),
    todayEvents() {
      const $d = this.$dayjs
      // проверка что дата d сегодняшняя но в будущем
      const isFutureToday = d => $d(d).isSame($d(),'d') && $d(d).isSameOrAfter($d())
      const iCanSeeEvent = grants => grants.all || grants.empls.some(empl => empl.id == this.uid)
      return this.events.filter(ev => isFutureToday(ev.date) && iCanSeeEvent(ev.grants))
    },
    
  },
  methods: {
    ...mapActions([GET_EVENTS_BY_CLIENT,GET_EVENTS]),
    async updateEvents() {
      if (this.isSuperAdmin) {
        await this.GET_EVENTS_BY_CLIENT(this.workspace);
      } else {
        await this.GET_EVENTS({clientId: this.workspace, userId: this.uid});
      }
    },
    timeClass(min) {
      if (min > 30) return 'darkgrey' // больше получаса - ничего не выдаляется
      else if (min > 15) return 'less30min' // от 15 до 30 минут - оранжевый
      else if (min > 5) return 'less15min' // от 5 до 15 минут - красный
      else return 'less5min' // меньше 5 минут
    },
    goEvent(ev) {
      this.$router.push('/calendar?_id='+ev._id)
    }
  },
  async created() {
    if (this.workspace) await this.updateEvents();
    setInterval(() => {
      // console.log('Checking events by interval');
      const now = this.$dayjs()
      this.eventList = this.todayEvents.map(ev => {
        const dur = this.$dayjs.duration(this.$dayjs(ev.date).diff(now))
        const h = dur.hours() ? `${dur.hours()} ${this.$t('common.time.h')} ` : ''
        const min = dur.minutes() ? `${dur.minutes()} ${this.$t('common.time.m')}` : ''
        const sec = dur.seconds() + ' ' + this.$t('common.time.s')
        const remain = [h,min,sec].join(' ')
        if (dur.asMinutes() < 1 && this.beep && !this.played.has(ev._id)) {
          this.beep.play()
          this.played.add(ev._id)
        } // = setInterval(()=>{}, 10000)
        return {
          time: this.$dayjs(ev.date).format("HH:mm"),
          name: ev.name,
          _id: ev._id,
          remain,
          duration: ev.duration,
          minutes: dur.asMinutes()
        }
      }).filter(ev => ev.minutes > 0)
    }, 5000)
  },
  async mounted() {
    this.beep = document.getElementById("beep"); // сам тег audio прописан в компоненте LoginBar, здесь он не работает
    // console.log('Beep is',this.beep);
  }
}
</script>

<style lang="stylus" scoped>
.panel-menu
  color dodgerblue
  cursor pointer
  @media (min-width: 420px) {
    margin-right 30px
  }
.event
  &-name
    font-size 12pt
  &-time
    font-size 16pt
  &-remain
    font-size 9pt
    &.less30min
      color orange
    &.less15min
      color orangered
    &.less5min
      color red
</style>