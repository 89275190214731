export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';

export const ADD_FOLLOW_USER = 'ADD_FOLLOW_USER';
export const DEL_FOLLOW_USER = 'DEL_FOLLOW_USER';

export const SET_EVENTS = 'SET_EVENTS';
export const ADD_EVENT = 'ADD_EVENT';
export const ADD_EVENTS = 'ADD_EVENTS';
export const REMOVE_EVENT = 'REMOVE_EVENT';
export const REMOVE_EVENTS = 'REMOVE_EVENTS';
export const CHANGE_EVENT = 'CHANGE_EVENT';
export const CHANGE_EVENTS = 'CHANGE_EVENTS';

export const SET_USERS = 'SET_USERS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const INSERT_USER_INFO = 'INSERT_USER_INFO';
export const INSERT_USERS = 'INSERT_USERS';
export const CHECK_USER_INFO = 'CHECK_USER_INFO';
export const IS_LOGGED_IN = 'IS_LOGGED_IN';
export const ERROR_REGISTER = 'ERROR_REGISTER';
export const ERROR_LOGIN = 'ERROR_LOGIN';
export const SUCCESS_REGISTER = 'SUCCESS_REGISTER';
export const SET_USER_DATA = 'SET_USER_DATA';
export const FORGOT_INFO = 'FORGOT_INFO';
export const RESET_INFO = 'RESET_INFO';
export const RELOAD_AVATAR = 'RELOAD_AVATAR';

export const ADD_ROLE = 'ADD_ROLE'
export const ADD_ROLE_TO_ALL = 'ADD_ROLE_TO_ALL'
export const REMOVE_ROLE = 'REMOVE_ROLE'
export const REMOVE_ROLE_FROM_ALL = 'REMOVE_ROLE_FROM_ALL'
export const REMOVE_USER_FILE = 'REMOVE_USER_FILE'

export const SET_SECTION = 'SET_SECTION'

export const EXPORT_PROCESSING = 'EXPORT_PROCESSING'

export const SET_PINNED_USERS = 'SET_PINNED_USERS'

export const SET_VACATIONS = 'SET_VACATIONS'
export const SET_USER_VACATIONS = 'SET_USER_VACATIONS'