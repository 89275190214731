import Vue from 'vue';
import Vuex from 'vuex';

import shower from './shower/shower.module';
import auth from './user/user.module';
import post from './post/post.module';
import group from './group/group.module';
import project from './project/project.module';
import structure from './structure/structure.module';
import notification from './notification/notification.module';
import notifications from './notifications/notifications.module';
import follower from './followers/follower.module';
import client from './client/client.module';
import article from './article/article.module';
import service from './service/service.module';
import poll from './poll/poll.module';
import shop from './shop/shop.module';
import task_tracker from './task_tracker/module';
import adaptation from './adaptation/module';
import tags from './tag/module';
import links_block from './links_block/module';
import mood from './mood/mood.module';
import chat from './chat/chat.module';
import mailing from './mailing/mailing.module';
import common from './common/common.module';

Vue.use(Vuex);
type T = {
  shower: any,
  auth: any,
  post: any,
  group: any,
  project: any,
  structure: any,
  notification: any,
  notifications: any,
  follower: any,
  client: any,
  article: any,
  service: any,
  poll: any,
  shop: any,
  task_tracker: any,
  adaptation: any,
  tags: any,
  links_block: any,
  mood: any,
  chat: any,
  common: any,
  mailing: any
}
export default new Vuex.Store<T>({
  modules: {
    shower,
    auth,
    post,
    group,
    project,
    structure,
    notification,
    notifications,
    follower,
    client,
    article,
    service,
    poll,
    shop,
    task_tracker,
    tags,
    adaptation,
    links_block,
    mood,
    chat,
    common,
    mailing
  },
});
