export const SET_SHOP = 'SET_SHOP'

export const SET_ACHIEVES = 'SET_ACHIEVES'
export const UPD_ACHIEVE = 'UPD_ACHIEVE'
export const ADD_ACHIEVE = 'ADD_ACHIEVE'
export const REMOVE_ACHIVE = 'REMOVE_ACHIVE'

export const SET_SHOP_ITEMS = 'SET_SHOP_ITEMS'
export const UPD_SHOP_ITEM = 'UPD_SHOP_ITEM'
export const ADD_SHOP_ITEM = 'ADD_SHOP_ITEM'
export const REMOVE_SHOP_ITEM = 'REMOVE_SHOP_ITEM'

export const SET_BALANCE = 'SET_BALANCE'
export const SET_BALANCES = 'SET_BALANCES'
export const SET_USER_ACHIEVES = 'SET_USER_ACHIEVES'
export const SET_STATISTIC = 'SET_STATISTIC'
export const DEL_STAT_ITEM = 'DEL_STAT_ITEM'

export const SET_ITEM_REST = 'SET_ITEM_REST'

// export const ADD_COINS = 'ADD_COINS'
