// import actions
import { GET_MOODS, SEND_MOOD, CHECK_METER_NEED } from '@/store/mood/actions.type'

// import mutations
import { ADD_MOOD, SET_METER_NEED, SET_MOODS } from '@/store/mood/mutations.type'

// import service functions
import { getMoods, sendMood, getCheckingMood } from './mood.service'


interface State {
  //<fields of model>
  moods: any[],
  needMeter: boolean,
}

const store: State = {
  // <fields>
  moods: [],
  needMeter: false,
}

const getters = {
  moods(state: State) {
    return state.moods;
  },
  needMeter(state:State) {
    return state.needMeter
  }
}

const mutations = {
  [ADD_MOOD](state: State, data: any) {
    state.moods.push(data)
  },
  [SET_MOODS](state: State, data: any) {
    state.moods = data || []
  },
  [SET_METER_NEED](state: State, data: boolean) {
    console.log('SET_METER_NEED',data);
    
    state.needMeter = data
  }
}

const actions = {
  [SEND_MOOD]: sendMood,
  [GET_MOODS]: getMoods,
  [CHECK_METER_NEED]: getCheckingMood
}

export default {
  state: store,
  getters,
  actions,
  mutations
};