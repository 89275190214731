import { $notif } from "@/helpers"

const genHandlers = ($vue, payload) => {
  const { title, msg, data, for: who_see } = payload
  const { uid } = $vue
  const handlers = {
    POSTS: {
      FEED_NEW: data => {
        if (data.user_id === uid) return; // автор поста пропускается
        // если есть список получателей, пропускаем если сотрудник не входит в него
        if (data.recipients.length && !data.recipients.includes(uid)) return; 
        $notif($vue, "success", title, msg);
      },
      COMPANY_NEW: {
        tmpl: 'posts.company_new'
      },
      BLOG_NEW: {
        tmpl: 'posts.blog_new'
      },
      GROUP_NEW: {
        tmpl: 'posts.group_new'
      },
      COMMENTED: {
        tmpl: 'posts.post_commented'
      },
      ANSWERED: {
        tmpl: 'posts.comment_answered'
      },
      MENTIONED: {
        tmpl: 'posts.user_mentioned'
      },
    },
    GROUPS: {
      NEW: {
        tmpl: 'groups.new'
      },
      NEW_EMPL: {
        tmpl: 'groups.new_empl'
      },
      INVITE: {
        tmpl: 'groups.invite'
      },
      REQUEST: {
        tmpl: 'groups.request'
      },
      APPROVE: {
        tmpl: 'groups.approve'
      },
    },
    EVENTS: {
      NEW: {
        tmpl: 'events.new'
      },
      MOVE: {
        tmpl: 'events.move'
      },
      DELETE: {
        tmpl: 'events.delete'
      },
    },
    POLLS: {
      NEW: {
        tmpl: 'polls.new'
      },
      FINISHED: {
        tmpl: 'polls.finished'
      },
    },
    SERVICES: {
      NEW_ADMIN: {
        tmpl: 'services.set_admin'
      },
      SENT_SENDER: {
        tmpl: 'services.sent_sender'
      },
      SENT_RECIPIENT: {
        tmpl: 'services.sent_recipient'
      },
    },
    RESP: {
      IDEA: {
        tmpl: 'resp.idea',
      },
      CLAIM: {
        tmpl: 'resp.claim',
      },
      VACANCY: {
        tmpl: 'resp.vacancy',
      },
    },
    SHOP: {
      TAKEN_MONEY: {
        tmpl: 'shop.taken_money'
      },
      TAKEN_ACHIEVE: {
        tmpl: 'shop.taken_achievement'
      },
      GIVEN: {
        tmpl: 'shop.given'
      },
      ITEM_BOUGHT: {
        tmpl: 'shop.item_bought'
      },
      ITEM_SOLD: {
        tmpl: 'shop.item_sold'
      },
    },
    OTHER: {
      BIRTHDAY: {
        tmpl: 'other.birthday'
      },
      SUBSCRIBE: {
        tmpl: 'other.new_subscriber'
      },
      NEW_EMPL: {
        tmpl: 'other.new_empl',
      },
      NEW_EMPL_GREET: {
        tmpl: 'other.new_empl_greet',
      }
    }
  }
  return handlers
}

export default {
  async backendMessage($vue, payload) {
    // data это notif_data, который формируется на бэке под каждый тип уведомления
    const { action, tmpl, title, msg, data, for: who_see } = payload
    const { uid } = $vue
    // const _ = action.indexOf('_')
    // const hndl = genHandlers($vue, payload)
    // const [Module, Act] = [action.slice(0, _), action.slice(_+1)]
    // hndl[Module][Act](data)
    // if (who_see?.length && !who_see.includes(uid)) return // если массив не пустой и там нет текущего юзера то конец

    console.log('Пришло backendMessage', payload);
    if ($vue.client_notif_channels.system == false) { // проверка что у компании отключены попапы
      console.log('Компания отключила все попапы');      
      return
    } 
    if ($vue.user_notif_channels.system == false) { // проверка что у юзера отключены попапы
      console.log('Сотрудник отключил все попапы'); 
      return
    }
    // настройки по организации наверно не нужны, т.к. сотрудник может их перекрыть
    // if ($vue.client_popups_templ_settings[action.replace('_','.').toLowerCase()] == false) {
    //   console.log(`Сотрудник отключил попапы для данного вида сообщений (${action})`); 
    //   return
    // }
    if ($vue.user_popups_templ_settings[tmpl] == false) {
      console.log(`Сотрудник отключил попапы для данного вида сообщений (${action})`); 
      return
    }
    
    if (action == 'PERIODICAL_EVENT') {
      if (payload.t == 'birthday') {
        await $vue.GET_ONE_NOTIFICATION({post_id: payload.post, parent: {type: 'all'}})
        $vue.ADD_NOTIFS_COUNT(1)
        $vue.LOAD_POST(payload.post)
        console.log('Посты про дни рождения добавлены');
      }
    }
    if (action === 'TEST') {
      $notif($vue, "info", 'common.title', msg)
    }
    if (action === 'OTHER_NEW_EMPL_GREET') {
      if (data.user_id !== uid) return; // только сотруднику
      $notif($vue, "info", title, msg)
    }
    if (action === 'OTHER_NEW_EMPL') {
      if (data.user_id === uid) return; // всем кроме самого юзера
      $notif($vue, "info", title, msg)
    }
    if (action === 'GROUPS_NEW') {
      // проверяем доступность по доступным группам
      //if (this.groups_available.some((g) => g._id == payload.group_id)) {
      if (data.author_id != uid) {
        $notif($vue, "info", title, msg);
        $vue.loadNotification(payload) // уведомление о новой группе
      } else return; // чтобы не было действий и звука, когда группа приватная
    }
    // if (action === 'GROUP_DELETED') {
    //   if (data.author_id != uid) {
    //     $notif($vue, "warning", title, msg);
    //   } else return;
    // }
    // новый участник в группе - может быть как самостоятельно вошедший, так и одобренный админом запрос
    if (action == 'GROUPS_NEW_EMPL') {
      const notWhoAdded = data.userId != uid // исключение добавляемого
      // const notAdmin = data.admin != uid // исключение админа
      const meIngroup: boolean = data.people.includes(uid) // проверка на вхождение в группу
      if (meIngroup && notWhoAdded) $notif($vue, "success", title, msg);
      else return
    }
    // заявка на вступление, показываем админу
    if (action == 'GROUPS_REQUEST') {
      if (data.admin != uid) return;
      $notif($vue, "success", title, msg);
    }
    // при одобрении шлется два оповещения - участнику об одобрении, остальным про нового
    if (action == 'GROUPS_APPROVE') {
      if (data.userId != uid) return;
      $notif($vue, "success", title, msg);
    }
    // отклонение заявки в группу, показываем подавшему - пока не надо
    // if (action == 'GROUP_REQ_REJECTED') {
    //   if (data.userId == uid) $notif($vue, "warning", "groups.reject", msg);
    // }
    // Уведомление о приглашении
    if (action == 'GROUPS_INVITE') {
      if (data.userId !== uid) return;
      $notif($vue, "warning", title, msg);
    }
    if (action == 'OTHER_SUBSCRIBE') {
      if (data.userID != uid) return
      $notif($vue, "warning", title, msg);
      await $vue.GET_INFO_ABOUT_ME();
    }
    // == События == 
    if (action == 'EVENTS_NEW') {
      if (!data.users.includes(uid) || data.creator == uid) return;
      $notif($vue, "success", title, msg);
    }
    if (action == 'EVENTS_MOVE') {
      if (!data.users.includes(uid) || data.creator == uid) return;
      $notif($vue, "warning", title, msg);
    }
    if (action == 'EVENTS_DELETE') {
      if (!data.users.includes(uid) || data.creator == uid) return;
      $notif($vue, "warning", title, msg);
    }
    // == Опросы ==
    if (action == 'POLLS_NEW') {
      if (!data.users.includes(uid) || data.author == uid) return;
      $notif($vue, "success", title, msg);
    }
    if (action == 'POLLS_FINISHED') {
      if (data.author != uid) return;
      $notif($vue, "success", title, msg);
    }
    // == Cервисы ==
    if (action == 'SERVICES_NEW_ADMIN') {
      if (!data.admins.includes(uid)) return;
      $notif($vue, "success", title, msg);
    }
    if (action == 'SERVICES_SENT_SENDER') {
      if (data.sender != uid) return;
      $notif($vue, "success", title, msg);
    }
    if (action == 'SERVICES_SENT_RECIPIENT') {
      if (!data.admins.includes(uid)) return;
      $notif($vue, "success", title, msg)
    }
    if (action == 'SERVICES_ORDER_FINISHED' || 
        action == 'SERVICES_STEP_PASSED' || 
        action == 'SERVICES_STEP_ACTIVE'
    ) {
      if (data.user != uid) return;
      $notif($vue, "success", title, msg)
    }
    // == Магазин ==
    if (action == 'SHOP_TAKEN_MONEY') {
      if (data.user != uid) return;
      await $vue.GET_BALANCE(uid)
      $notif($vue, "success", title, msg);
      $vue.playSound('coins')
      return
    }
    if (action == 'SHOP_TAKEN_ACHIEVE') {
      if (data.user != uid) return;
      $notif($vue, "success", title, msg);
    }
    if (action == 'SHOP_GIVEN') {
      if (data.shop_manager != uid) return;
      $notif($vue, "success", title, msg);
    }
    if (action == 'SHOP_ITEM_BOUGHT') { // покупка человеком
      if (data.user_id != uid) return;
      $notif($vue, "success", title, msg);
    }
    if (action == 'SHOP_ITEM_SOLD') { // оповещение админа магазина о покупке
      if (!data.managers.includes(uid)) return;
      $notif($vue, "success", title, msg);
    }
    // == Идеи и жалобы ==
    if (action == 'RESP_IDEA') { // 
      if (!data.ids.includes(uid)) return;
      $notif($vue, "success", title, msg);
    }
    if (action == 'RESP_CLAIM') { // 
      if (!data.ids.includes(uid)) return;
      $notif($vue, "success", title, msg);
    }
    if (action == 'RESP_VACANCY') { // 
      if (!data.user_ids.includes(uid)) return;
      $notif($vue, "success", title, msg);
    }
    // == Посты, упоминания, комменты
    if (['POSTS_FEED_NEW','POSTS_COMPANY_NEW','POSTS_GROUP_NEW','POSTS_BLOG_NEW'].includes(action)) { // 
      if (data.user_id === uid) return; // автор поста пропускается
      // если есть список получателей, пропускаем если сотрудник не входит в него
      if (data.recipients.length && !data.recipients.includes(uid)) return; 
      $notif($vue, "success", title, msg);
    }
    if (['POSTS_COMMENTED','POSTS_ANSWERED'].includes(action)) {
      if (data.owner_id !== uid) return;  // в owner_id автор коммента/поста
      $notif($vue, "success", title, msg);
    }
    if (action == 'POSTS_MENTIONED') {
      if (!data.users.includes(uid)) return;
      $notif($vue, "success", title, msg);
    }
    if (action == 'OTHER_BIRTHDAY') {
      // в emails те у кого день рождения
      // Если ДР у одного сотрудника, то не показываем ему. 
      // Если у нескольких то показываем, т.к. на всех одно общее сообщение
      if (data.emails.length === 1 && data.users_id[0] === uid) return;  
      $notif($vue, "success", title, msg);
    }
    // == Адаптация
    // Старт - для сотрудника, ментора и начальника, т.к. стартует админ
    if (action == 'ADAPT_START') {
      if (!data.notifs_for.includes(uid)) return;
      $notif($vue, "success", title, msg);
    }
    if (action == 'ADAPT_EMPL_START') {
      if (!data.notifs_for.includes(uid)) return;
      $notif($vue, "success", title, msg);
    }
    // окончание - так же
    if (action == 'ADAPT_FINISH') {
      if (!data.notifs_for.includes(uid)) return;
      $notif($vue, "success", title, msg);
    }
    // новая адаптация - для его участников (менторы, эйчары)
    if (action == 'ADAPT_NEW_TRACK') {
      if (!data.notifs_for.includes(uid)) return;
      $notif($vue, "success", title, msg);
    }
    // Новый автоматический шаг у сотрудника - получает сам сотрудник
    if (action == 'ADAPT_STEP_CREATED') {
      if (data.user_id != uid) return;
      $notif($vue, "success", title, msg);
    }
    if (action == 'ADAPT_STEP_FINISH') {

    }
    if (action == 'ADAPT_STEP_OUTDATE') {
      if (data?.user_id != uid) return;
      $notif($vue, "warning", title, msg);
    }
    if (action == 'ADAPT_SCENARIO_CHANGED') {

    }
    $vue.playSound()
  }
}