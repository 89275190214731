// import actions
import { 
  CHAT_SEND_MSG, CHAT_ADD_ROOM, CHAT_CHANGE_ROOM, CHAT_DELETE_ROOM,
  CHAT_GET_ROOMS, CHAT_GET_MESSAGES 
} from '@/store/chat/actions.type'

// import mutations
import { CHAT_SET_MESSAGES, CHAT_SET_ROOMS, CHAT_SET_ROOM, CHAT_PUSH_ROOM, CHAT_REMOVE_ROOM, CHAT_PUSH_MESSAGE } from '@/store/chat/mutations.type'

// import service functions
import { getRooms, addRoom, editRoom, delRoom, getMessages, addMessage} from './chat.service'


interface State {
  rooms: Object[] | null,
  messages: Object[] | null
}

const store: State = {
  rooms: null,
  messages: null
}

const getters = {
  rooms(state: State) {
    return state.rooms;
  },
  chat_messages(state: State) {
    return state.messages;
  },
}

const mutations = {
  [CHAT_SET_ROOMS](state: State, data: any) {
     state.rooms = data;
  },
  [CHAT_SET_ROOM](state: State, data: any) {
    state.rooms = state.rooms?.map((r:any) => r.roomId == data.roomId ? data : r) || [];
 },
 [CHAT_SET_MESSAGES](state: State, data: any) {
    state.messages = data;
  },
  [CHAT_PUSH_MESSAGE](state: State, msg: any) {
    state.messages = state.messages ? [...state.messages, msg] : [msg];
  },
  [CHAT_PUSH_ROOM](state: State, room: any) {
    if (state.rooms == null) state.rooms = [room]
    else state.rooms = [...state.rooms, room];
  },
  [CHAT_REMOVE_ROOM](state: State, roomId: Number | String) {
    state.rooms = state.rooms?.filter((r:any) => r.roomId != roomId) || []
  }
}

const actions = {
  [CHAT_ADD_ROOM]: addRoom,
  [CHAT_DELETE_ROOM]: delRoom,
  [CHAT_SEND_MSG]: addMessage,
  [CHAT_GET_ROOMS]: getRooms,
  [CHAT_GET_MESSAGES]: getMessages,
  [CHAT_CHANGE_ROOM]: editRoom
}

export default {
  state: store,
  getters,
  actions,
  mutations
};