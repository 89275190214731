import Vue from 'vue';
import * as Mutations from './mutations.type'
import { BPStep, TStepPassData } from './types';

type result = {
  result: any
}

/**
 * Получение всех сервисов. Пока что с привязкой к статье
 * @param context Контекст стора
 * @param article_id Код статьи. В будущем будет убрано или заменено на код клиента
 */
export const getServices = async (context: any, article_id: string) => {
  const {data} = await Vue.axios.get<result>(`api/service/byArticle/${article_id}`)
  context.commit(Mutations.SET_SERVICES, data.result);
}

// Получить один бизнес-процесс
export const getBP = async (context: any, bp_id: string) => {
  const {data} = await Vue.axios.get<result>(`api/bp/${bp_id}`)
  context.commit(Mutations.SET_BUSINESS_PROCESS, data.result);
}

/**
 * Добавление нового сервиса
 * @param context Контекст стора
 * @param article_id Код статьи
 * @param data Данные по сервису
 */
export const newBP = async (context: any, bp: any) => {
  const {data} = await Vue.axios.post<result>(`api/bp${bp.template ? '/start' : ''}`, bp)
  context.commit(Mutations.ADD_BP, data.result);
  return data.result
}

/**
 * Обновление данных в сервисе
 * @param context Контекст стора
 * @param srv_id Идентификатор сервиса
 */
export const updateBP = async (context: any, service: any) => {
  const {data} = await Vue.axios.put<result>(`api/bp/${service._id}`, service)
  context.commit(Mutations.SET_BP, data.result);
  context.commit(Mutations.SET_BUSINESS_PROCESS, data.result);
}

/**
 * Удаление сервиса
 * @param context Контекст стора
 * @param srv_id Идентификатор сервиса
 */
export const delBP = async (context: any, srv_id: string) => {
  const {data} = await Vue.axios.delete(`api/bp/${srv_id}`)
  context.commit(Mutations.REMOVE_BP, data.result);
}


//// ГРУППЫ СЕРВИСОВ

/**
 * Получение всех сервисов. Пока что с привязкой к статье
 * @param context Контекст стора
 * @param client_id Код клиента
 */
export const getServiceGroups = async (context: any, client_id: string) => {
  const {data} = await Vue.axios.get<result>(`api/service_group/byClient/${client_id}`)
  context.commit(Mutations.SET_SERVICE_GROUPS, data.result);
}

/**
 * Добавление нового сервиса
 * @param context Контекст стора
 * @param data Данные по группе сервисов
 */
export const newServiceGroup = async (context: any, serviceGroup: any) => {
  const {data} = await Vue.axios.post<result>(`api/service_group`, serviceGroup)
  context.commit(Mutations.ADD_SERVICE_GROUP, data.result);
}

/**
 * Обновление данных в сервисе
 * @param context Контекст стора
 * @param srv_id Идентификатор группы сервиса
 */
export const updateServiceGroup = async (context: any, serviceGroup: any) => {
  const {data} = await Vue.axios.put<result>(`api/service_group/${serviceGroup._id}`, serviceGroup)
  context.commit(Mutations.SET_SERVICE_GROUP, data.result);
}

/**
 * Удаление сервиса
 * @param context Контекст стора
 * @param srv_id Идентификатор группы сервиса
 */
export const delServiceGroup = async (context: any, srv_id: string) => {
  const {data} = await Vue.axios.delete(`api/service_group/${srv_id}`)
  context.commit(Mutations.REMOVE_SERVICE_GROUP, data.result)
  return data.result
}

// Бизнес процессы (шаблоны)
export const getBusinessProcesses = async (context: any, client_id: string) => {
  const {data} = await Vue.axios.get(`api/bp/tmpls/${client_id}`)
  context.commit(Mutations.SET_BUSINESS_PROCESSES, data.result)
  return data.result
}

type GetProcessesReq = {
  user_id: string, 
  bp_id: string, 
  finished: boolean,
  periodFrom: string,
  periodTo: string,
};

// Заявки по сотруднику и бизнес-процессу 
export const getRunningProcesses = async (context: any, data: GetProcessesReq) => {
  const q: string[] = []
  if (data.bp_id) q.push('bp='+data.bp_id)
  if (data.finished) q.push('finished=1')
  if (data.periodFrom) q.push('from='+data.periodFrom)
  if (data.periodTo) q.push('to='+data.periodTo)
  const query = q.length > 0 ? '?'+q.join('&') : ''
  const url = `api/bp/runned/${data.user_id}${query}`
  const {data: result} = await Vue.axios.get(url)
  const mut = Mutations.SET_ORDERS_TABLE
  context.commit(mut, result)
  return result
}

// Конвертация старых процессов
export const convertProcesses = async (context: any, client_id: string) => {
  const {data} = await Vue.axios.post(`api/bp/convert/${client_id}`)
  context.commit(Mutations.SET_BUSINESS_PROCESSES, data.result)
  return data.result
}

/// Шаги бизнес-процесса

/**
 * Adds a new step to a business process.
 *
 * @param {Object} context - The context object for the Vuex store.
 * @param {Object} data - The data object containing the business process ID and the new step.
 * @param {string} data.bp - The ID of the business process.
 * @param {BPStep} data.step - The new step to be added.
 * @return {Promise<BPStep>} The newly added step.
 */
export const newStep = async (context: any, data: {bp: string, step: BPStep}) => {
  const {data: resultData} = await Vue.axios.post(`api/bp/${data.bp}/step`, data.step)
  context.commit(Mutations.ADD_STEP, resultData)
  return resultData
}

/**
 * Adds a new step to a business process.
 *
 * @param {Object} context - The context object for the Vuex store.
 * @param {Object} data - The data object containing the business process ID and the new step.
 * @param {string} data.bp - The ID of the business process.
 * @param {BPStep} data.step - The new step to be added.
 * @return {Promise<BPStep>} The newly added step.
 */
export const updateStep = async (context: any, data: {bp: string, step: BPStep}) => {
  const {data: resultData} = await Vue.axios.put(`api/bp/${data.bp}/step`, data.step)
  context.commit(Mutations.CHANGE_STEP, resultData)
  return resultData
}

export const deleteStep = async (context: any, data: {bp: string, step_id: string}) => {
  const {data: resultData} = await Vue.axios.delete(`api/bp/${data.bp}/step/${data.step_id}`)
  context.commit(Mutations.REMOVE_STEP, resultData)
  return resultData
}


export const passStep = async (context: any, data: TStepPassData) => {
  const {data: resultData} = await Vue.axios.post(`api/bp/${data.bp_id}/pass_step`, data)
  context.commit(Mutations.SET_BUSINESS_PROCESS, resultData)
  return resultData
}