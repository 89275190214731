// MUTATIONS
import {
  SET,
  SET_PROGRESS,
  SET_PROGRESS_VISIBLE,
  SET_FEED,
  TOGGLE_MOBILE_MENU
} from '@/store/common/mutations.type'
import { SET_ITEMS, SET_ITEM, ADD_ITEM, REMOVE_ITEM, UPD_ITEM, UPD_LIST_ITEM } from './mutations.type';
import { GET_ITEM, GET_ITEMS, EDIT_ITEM, NEW_ITEM, DEL_ITEM } from './actions.type';
import { delModuleItem, editModuleItem, getModuleItem, getModuleItems, newModuleItem } from './common.service';

// Store interface

enum WallFeed {
  user = 'user',
  company = 'company'
}
interface State {
  progress: Number,
  progressShow: Boolean,
  wallFeed: WallFeed,
  mainMobileMenu: Boolean,
  vendor: string,
}

// STORE 
const store: State = {
  progress: 0,
  progressShow: false,
  wallFeed: WallFeed.company,
  mainMobileMenu: false,
  vendor: '',
}

// GETTERS
const getters = {
  progress(state: State) {
    return state.progress;
  },
  progressShow(state: State) {
    return state.progressShow;
  },
}

// Универсальные операции над объектами любых модулей (CRUD)
const actions = {
  [GET_ITEMS]: getModuleItems,
  [GET_ITEM]: getModuleItem,
  [NEW_ITEM]: newModuleItem,
  [EDIT_ITEM]: editModuleItem,
  [DEL_ITEM]: delModuleItem, 
}

type CommonData = {
  state: any,
  field?: string,
  data: any
}

// MUTATIONS
const mutations = {
  // универсальная мутация для простых присвоений
  [SET](state: State, data: {module: string, field: string, value: any}) {
    state[data.module][data.field] = data.value;
  },
  [SET_PROGRESS](state: State, value: Number) {
    state.progress = value;
  },
  [SET_PROGRESS_VISIBLE](state: State, value: Boolean) {
    state.progressShow = value;
  },
  [SET_FEED](state: State, data: WallFeed) {
    state.wallFeed = data
  },
  [TOGGLE_MOBILE_MENU](state: State, show: Boolean) {
    state.mainMobileMenu = !state.mainMobileMenu
  },
  // Универсальные мутирующие функции
  [SET_ITEMS](state: State, data: CommonData) {
    if (!data.state) return
    data.state[data.field || 'list'] = data.data
  },
  [SET_ITEM](state: State, data: CommonData) {
    if (!data.state)  // если не указан отдельный стейт, устанавливаем здесь в текущем
      state[data.field || 'item'] = data.data
    else 
      data.state[data.field || 'item'] = data.data
  },
  [ADD_ITEM](state: State, data: CommonData) {
    if (!data.state) return
    data.state[data.field || 'list'].push(data.data)
  },
  [UPD_LIST_ITEM](state: State, data: CommonData) {
    if (!data.state) return
    data.state[data.field || 'list'] = data.state[data.field || 'list'].map(el => el._id == data.data._id ? data.data : el)
  },
  [UPD_ITEM](state: State, data: CommonData) {  // полностью дублирует SET_ITEM...
    if (!data.state) return
    data.state[data.field || 'item'] = data.data
  },
  [REMOVE_ITEM](state: State, data: CommonData) {
    if (!data.state || !data.data?._id) return // если нет стейта или нет _id в данных, не продолжаем
    data.state[data.field || 'list'] = data.state[data.field || 'list'].filter(el => el._id != data.data._id)
  },
}

export default {
  namespaced: true,
  state: store,
  getters,
  actions,
  mutations,
};
