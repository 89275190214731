import Vue from 'vue';
import { CHAT_SET_MESSAGES, CHAT_SET_ROOMS, CHAT_SET_ROOM, CHAT_PUSH_ROOM, CHAT_REMOVE_ROOM, CHAT_PUSH_MESSAGE } from './mutations.type'

export const getRooms = (context: any, client_id: String) => {
  return Vue.axios
    .get(`api/chat/rooms/${client_id}`)
    .then((res: any) => {
      context.commit(CHAT_SET_ROOMS, res.data.result)
      return res.data.result
    })
}

export const addRoom = (context: any, data: any) => {
  return Vue.axios
    .post(`api/chat/room`, data)
    .then((res: any) => {
      context.commit(CHAT_PUSH_ROOM, res.data.result.room)
      return res.data.result.room
    })
}

export const editRoom = (context: any, data: any) => {
  return Vue.axios
    .put(`api/chat/room`, data)
    .then((res: any) => {
      context.commit(CHAT_SET_ROOM, res.data.result.room)
      return res.data.result.room
    })
}

export const delRoom = (context: any, roomId: any) => {
  return Vue.axios
    .delete(`api/chat/room/${roomId}`)
    .then((res: any) => {
      context.commit(CHAT_REMOVE_ROOM, res.data.result)
    })
}

export const getMessages = (context: any, room_id: String) => {
  return Vue.axios
    .get(`api/chat/room/${room_id}/msgs`)
    .then((res: any) => {
      context.commit(CHAT_SET_MESSAGES, res.data.result)
    })
}

export const addMessage = (context: any, data: any) => {
  const d = new Date()
  const msg_data = {
    roomId: data.roomId,
    client_id: data.client_id,
    msg: {
      _id: ''+d.getTime()+d.getMilliseconds(),
      content: data.content,
      senderId: data.user_id,
      username: data.username,
      date: d.toLocaleDateString('ru',{day: 'numeric', month: 'long', year: 'numeric'}),
      timestamp: d.toLocaleTimeString(),
      seen: false,
      file: data.file
    }
  }
  return Vue.axios
    .post(`api/chat/msg`, msg_data)
    .then((res: any) => {
      context.commit(CHAT_PUSH_MESSAGE, res.data.result.msg)
    })
}