export const LOGIN = 'LOGIN';
export const LOGIN_WITH_GOOGLE = 'LOGIN_WITH_GOOGLE';
export const LOGIN_WITH_OUTLOOK = 'LOGIN_WITH_OUTLOOK';
export const GET_INFO_ABOUT_ME = 'GET_INFO_ABOUT_ME';
export const EDIT_USER = 'EDIT_USER';

export const MARK_USER_DELETED = 'MARK_USER_DELETED';
export const UNMARK_USER_DELETED = 'UNMARK_USER_DELETED';

export const SET_PINNED = 'SET_PINNED';
export const MOVE_PINNED = 'MOVE_PINNED';

// EVENTS
export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_PERIODIC_EVENT = 'CREATE_PERIODIC_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_PERIODIC_EVENT = 'UPDATE_PERIODIC_EVENT';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_BY_CLIENT = 'GET_EVENTS_BY_CLIENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_PERIODIC_EVENT = 'DELETE_PERIODIC_EVENT';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_FILTERED = 'GET_USERS_FILTERED';
export const GET_USER = 'GET_USER';

export const LOGOUT = 'LOGOUT';
export const INSERT_USER_INFO = 'INSERT_USER_INFO';
export const SAVE_USERS = 'SAVE_USERS';
export const CHECK_USER_INFO = 'CHECK_USER_INFO';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_HARD = 'RESET_PASSWORD_HARD';

export const INSERT_ROLE = 'INSERT_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE'

export const SERVICE_SEND = 'SERVICE_SEND'
export const DELETE_HR_FILE = 'DELETE_HR_FILE'

export const SEND_IDEA = 'SEND_IDEA'
export const SEND_VIOLATION_REPORT = 'SEND_VIOLATION_REPORT'

export const EXPORT_USER = 'EXPORT_USER'
export const EXPORT_USERS = 'EXPORT_USERS'

export const USERS_LOAD_HRM = 'USERS_LOAD_HRM'
export const USERS_LOAD_STRUCT_HRM = 'USERS_LOAD_STRUCT_HRM'
export const RESET_TO_HRM = 'RESET_TO_HRM'

export const GET_VACATIONS = 'GET_VACATIONS'
export const SAVE_VACATION = 'SAVE_VACATION'
export const DEL_VACATION = 'DEL_VACATION'


