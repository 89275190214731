export const SEND_NEW_POST = 'SEND_NEW_POST';

export const GET_COMPANY_FEED = 'GET_COMPANY_FEED'
export const GET_USER_FEED = 'GET_USER_FEED'

export const GET_POSTS = 'GET_POSTS';
export const GET_POSTS_ALL = 'GET_POSTS_ALL'
export const GET_POSTS_OF_BLOG = 'GET_POSTS_OF_BLOG';
export const GET_POSTS_OF_FLWS = 'GET_POSTS_OF_FLWS';
export const GET_POSTS_OF_GROUP = 'GET_POSTS_OF_GROUP';
export const GET_POSTS_OF_GROUPS = 'GET_POSTS_OF_GROUPS';
export const GET_POST = 'GET_POST';
export const LOAD_POST = 'LOAD_POST';
export const UPDATE_POST = 'UPDATE_POST';
export const DELETE_POST = 'DELETE_POST';
export const DELETE_COMMENT = 'DELETE_COMMENT';

export const SEND_LIKE = 'SEND_LIKE';
export const SEND_COMMENT = 'SEND_COMMENT';
export const REPOST = 'REPOST';
export const EDIT_POST = 'EDIT_POST';
export const EDIT_COMMENT = 'EDIT_COMMENT';

// Комментарии к посту что я новый сотрудник
export const GET_COMMENTS = 'GET_COMMENTS'
export const GET_MENTIONS = 'GET_MENTIONS'

export const VIEW_POST = 'VIEW_POST'