// import {} from './actions.type'
// import {} from './mutations.type'

import { getTaskOfDesk, addTaskToDesk, delLaneOfDesk, editTaskOnDesk, delTask, moveTaskToLane, finishDesk, saveTaskAlone } from "./service"
import { GET_TASK, NEW_TASK, CREATE_TASK, EDIT_TASK , DEL_TASK, DEL_LANE, MOVE_TASK, DESK_FINISH } from "./actions.type"
import { ADD_TASK, CHANGE_TASK, REMOVE_TASK, CHANGE_FILES } from "./mutations.type"

import { Desk, Task } from './types'

interface State {
  list: Desk[], // список досок для начальной страницы
  item: Desk | null,  // активная доска для ее страницы 
  activeTask: Task | null, // открытая в окне редактирования задача
}


// STORE 
const store: State = {
  list: [], // единый реквизит для списка объектов из общего модуля CRUD (common)
  item: null, // аналогично для активного объекта
  activeTask: null,
}

const actions = {
  [GET_TASK]: getTaskOfDesk,
  [CREATE_TASK]: saveTaskAlone,
  [NEW_TASK]: addTaskToDesk,
  [EDIT_TASK]: editTaskOnDesk,
  [DEL_TASK]: delTask,
  [DEL_LANE]: delLaneOfDesk,
  [MOVE_TASK]: moveTaskToLane,
  [DESK_FINISH]: finishDesk,
}

const getLane = (state, id: string) => state.item?.lanes.find(l => l._id === id)

// изменение задач внутри активной доски
const mutations = {
  [ADD_TASK](state: State, data: {task: Task, desk_id: string, lane_id: string}) {
    const lane = getLane(state, data.lane_id)
    lane?.tasks.push(data.task)
  },
  [CHANGE_TASK](state: State, data: {task: Task, desk_id: string, lane_id: string}) {
    const lane = getLane(state, data.lane_id)
    if (!lane?.tasks) return
    lane.tasks = lane.tasks.map(t => t._id == data.task._id ? data.task : t)
  },
  [REMOVE_TASK](state: State, data: {task_id: string, desk_id: string, lane_id: string}) {
    const lane = getLane(state, data.lane_id)
    if (!lane?.tasks) return
    lane.tasks = lane.tasks.filter(t => t._id != data.task_id)
  },
  [CHANGE_FILES](state: State, attachments: any[] ) {
    if (state.activeTask) state.activeTask.attachments = attachments
  },
}

export default {
  namespaced: true,
  state: store,
  // getters,
  actions,
  mutations,
};