export const GET_SHOP = 'GET_SHOP'
export const SAVE_SHOP = 'SAVE_SHOP'

export const GET_ACHIEVMENTS = 'GET_ACHIEVMENTS'
export const GET_USER_ACHIEVMENTS = 'GET_USER_ACHIEVMENTS'
export const SEND_ACHIEV = 'SEND_ACHIEV'
export const EDIT_ACHIEV = 'EDIT_ACHIEV'
export const DEL_ACHIEV = 'DEL_ACHIEV'

export const GET_SHOP_ITEMS = 'GET_SHOP_ITEMS'
export const SEND_SHOP_ITEM = 'SEND_SHOP_ITEM'
export const EDIT_SHOP_ITEM = 'EDIT_SHOP_ITEM'
export const DEL_SHOP_ITEM = 'DEL_SHOP_ITEM'

export const ACCRUE_COINS = 'ACCRUE_COINS'
export const CANCEL_ACCRUAL = 'CANCEL_ACCRUAL'
export const BUY_ITEM = 'BUY_ITEM'
export const GET_BALANCE = 'GET_BALANCE'
export const ADD_ITEM_AMOUNT = 'ADD_ITEM_AMOUNT'

export const GET_STATS = 'GET_STATS'
export const GET_ALL_BALANCES = 'GET_ALL_BALANCES'


