import {
  SET_NOTIFICATION,
  SET_NOTIFICATIONS,

  SET_MESSAGES,
  //MARK_MESSAGE_READ,
  ADD_MESSAGE,
  SOCKET_NEW_MESSAGE
} from '@/store/notification/mutations.type';

import {
  PUT_NOTIFICATION,
  PUT_NOTIFICATIONS,
  GET_NOTIFICATION,
  GET_NOTIFICATIONS,
  CREATE_MESSAGE,
  SEND_EMAIL_NOTIFS,
  //READ_MESSAGES
} from '@/store/notification/actions.type';

import {
  postNotification,
  postNotifications,
  getNotification,
  getNotifications,
  createMessage,
  getMessages,
  newSocketMessage,
  sendEmailNotifications,
  //markMessagesAsRead
} from './notification.service';

interface State {
  notifications: any[];
  notification: any[];
  notifs: any[],
  messages: any[];
  socketMessage: any;
}

const store: State = {
  notifications: [],
  notification: [],
  notifs: [],
  messages: [],
  socketMessage: null
};

const getters = {
  notification(state: State) {
    return state.notification;
  },
  notifications(state: State) {
    return state.notifications;
  },
  messages(state: State) {
    return state.messages;
  },
  // notifs(state: State) {
  //   return state.messages
  //     .filter((msg) => isVisible(msg))
  //     .map((msg) => ({
  //       type: msg.msgType,
  //       creator: getCreatorName(msg),
  //       title: msgTitles[msg.msgType], // TODO: подстроить под разные типы сообщений
  //       text: msg.text,
  //       id: msg.linkedObjId,
  //       created: msg.dateCreated,
  //       name: getGroupName(msg.linkedObjId),
  //     }))
  // },
  getSocketMessage(state: State) {
    return state.socketMessage
  }
};

const mutations = {
  [SET_NOTIFICATION](state: State, notification: any) {
    state.notification = notification;
  },
  [SET_NOTIFICATIONS](state: State, notifications: any) {
    state.notifications = notifications;
  },
  [SET_MESSAGES](state: State, messages: any) {
    state.messages = messages;
  },
  // [MARK_MESSAGE_READ](state: State, msg: any) {
  //   const i = state.messages.findIndex(m => m._id == msg._id);
  //   state.messages.splice(i, 1, msg)
  // },
  [ADD_MESSAGE](state: State, msg: any) {
    state.messages = [msg, ...state.messages];
  },
  // Только изменение state для обновления UI
  [SOCKET_NEW_MESSAGE](state: State, msg: any) {
    state.socketMessage = msg;
  }
};

const actions = {
  [PUT_NOTIFICATION]: postNotification,
  [PUT_NOTIFICATIONS]: postNotifications,
  [GET_NOTIFICATION]: getNotification,
  [GET_NOTIFICATIONS]: getNotifications,
  [CREATE_MESSAGE]: createMessage,
  // [GET_MESSAGES]: getMessages,
  //[READ_MESSAGES]: markMessagesAsRead
  [SEND_EMAIL_NOTIFS]: sendEmailNotifications,
  "socket_socketMessage": newSocketMessage,    // помещение сообщения в БД и далее вызов SOCKET_NEW_MESSAGE
};

export default {
  state: store,
  getters,
  actions,
  mutations
};
