// добавление данных по новому клиенту в их список
export const ADD_CLIENT = 'ADD_CLIENT'
// удаление клиента из списка
export const REMOVE_CLIENT = 'REMOVE_CLIENT'
// заполнение общего массива клиентов стейта и одного клиента
export const SET_CLIENTS = 'SET_CLIENTS'
export const SET_CLIENT = 'SET_CLIENT'

// заполнение данных клиента для редактирования и просмотра
export const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT'

// обновление данных существующего клиента
export const UPDATE_CLIENT = 'UPDATE_CLIENT'

// установка кода клиента, пользователь которого вошел в систему
//  - надо переделать, слить с процедурой логина всех типов
export const SET_LOGGED_CLIENT = 'SET_LOGGED_CLIENT'

// смена клиента для суперадмина
export const CHANGE_WORKSPACE = 'CHANGE_WORKSPACE'

// статистика по данным в БД
export const SET_STATISTIC = 'SET_STATISTIC'
export const SET_FILES_STAT = 'SET_FILES_STAT'
export const UPDATE_STATISTIC = 'UPDATE_STATISTIC'

export const SET_DIALOG_MESSAGE = 'SET_DIALOG_MESSAGE'

export const SET_FEATURES = 'SET_FEATURES'
export const SET_FEATURE = 'SET_FEATURE'

export const SET_HANGED_FILES = 'SET_HANGED_FILES'

export const SET_JOURNAL = 'SET_JOURNAL'
export const ADD_JOURNAL_REC = 'ADD_JOURNAL_REC'

export const SET_THANKS = 'SET_THANKS'

export const SET_PROCESSING = 'SET_PROCESSING' // флаг для процесса выгрузки

export const SET_BDAYS = 'SET_BDAYS'
export const SET_NOTIF_TEMPLATES = 'SET_NOTIF_TEMPLATES'
export const UPD_NOTIF_TEMPLATE = 'UPD_NOTIF_TEMPLATE'
export const REMOVE_NOTIF_TEMPLATE = 'REMOVE_NOTIF_TEMPLATE'