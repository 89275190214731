import {
    FOLLOW_USER_STATUS,
} from './mutations.type';
import {
    USER_FOLLOW, USER_UNFOLLOW
} from './actions.type';
import {followUser, unfollowUser} from './follower.service';

interface State {
    follower: string | null;
}

const store: State = {
    follower: '',
};

const getters = {
    followers(state: State) {
        return state.follower;
    },
};

const mutations = {
    [FOLLOW_USER_STATUS](state: State, follower: string) {
        state.follower = follower;
    },
};

const actions = {
    [USER_FOLLOW]: followUser,
    [USER_UNFOLLOW]: unfollowUser,
};

export default {
    state: store,
    getters,
    actions,
    mutations
};
