<template>
  <svg :class="iconClass" :width="width" :height="width" :style="{ color: color }" @click="$emit('click')">
    <use :xlink:href="iconHref"></use>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: '16px'
    },
    // height: {
    //   type: String,
    //   default: '24px'
    // },
    color: {
      type: String,
      default: '#5656E0' //'#3380d8'
    },
    iconClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconHref() {
      return `/all-icons.svg#${this.icon}`;
    }
  }
};
</script>

<style scoped>
/* svg {
  fill: currentColor;
  stroke: currentColor;
} */
</style>
