export const ADD_DEP = 'ADD_DEP';

export const SET_STRUCTURE = 'SET_STRUCTURE'

export const SET_DEPT_USERS = 'SET_DEPT_USERS'     // помещение в стор набора записей
export const ADD_DEPT_USERS = 'ADD_DEPT_USERS'       // добавить новую запись
export const UPDATE_DEPT_USER = 'CHANGE_DEPT_USER' // изменить запись
export const REMOVE_DEPT_USER = 'DEL_DEPT_USER'    // удалить запись
export const DIALOGS = 'DIALOGS'    // управление диалоговыми окнами

export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM'    // управление диалоговыми окнами
