// Админская часть
export const ADD_USER_TO_TRACK = 'ADD_USER_TO_TRACK'; // добавление сотрудника в трек
export const ADD_STAGE = 'ADD_STAGE'; // добавление этапа сценария
export const EDIT_STAGE = 'EDIT_STAGE'; // изменение этапа сценария
export const DELETE_STAGE = 'DELETE_STAGE'; // удаление этапа сценария
export const ADD_STEP = 'ADD_STEP'; // добавление шага
export const EDIT_STEP = 'EDIT_STEP'; // изменение шага сценария
export const DELETE_STEP = 'DELETE_STEP'; // удаление шага сценария
export const GET_TRACK_MEMBERS = 'GET_TRACK_MEMBERS'; // получение участников трека
export const EDIT_USER_TRACK = 'EDIT_USER_TRACK'; // изменение участников трека (кроме новичка)
export const GET_ADAPTATIONS = 'GET_ADAPTATIONS'; // получение новичков для кураторов
export const GET_USER_TRACK_FOR = 'GET_USER_TRACK_FOR'; // получение трека новичка ментором/руководителем
export const GET_SETTINGS = 'GET_SETTINGS'; // получение настроек трека
export const SAVE_SETTINGS = 'SAVE_SETTINGS'; // запись настроек трека
export const COPY_TRACK = 'COPY_TRACK'; // копирование трека
export const DEL_TRACK = 'DEL_TRACK'; // копирование трека
// пользовательская часть
export const GET_USER_TRACK = 'GET_USER_TRACK'; // получение трека новичком
export const FINISH_STEP = 'FINISH_STEP'; // завершение шага сценария
export const UNFINISH_STEP = 'UNFINISH_STEP'; // откат завершения шага сценария
export const RESUME_STEP = 'RESUME_STEP'; // восстановление шага сценария независимо от других
export const DEL_USER_STEP = 'DEL_USER_STEP'; // удаление шага в треке адаптации сотрудника
export const DEL_ADAPTATION = 'DEL_ADAPTATION'; // откат завершения шага сценария
