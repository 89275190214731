import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['workspace','userIsAdmin','apiURL','uid']),
    ...mapState({
      user: state => state?.auth?.userData,
      isAdmin: (state, root) => root.userIsAdmin, // TODO: в будущем заменить userIsAdmin на isAdmin и убрать тут
      isSuperAdmin: (state, root) => root.userIsSuperAdmin, 
    }),
    devMode() {
      return process.env.NODE_ENV == 'development'
    }
  },

  methods: {
    hasRole(role) {
      return this.user?.roles?.includes(role)
    }
  }
}