export default [
	{
		url: "company",
		module: "wall",
		icon: "home",
	},
	{
		url: "structure_new",
		module: "structures",
		icon: "tree",
	},
	{
		url: "addressBook",
		module: "address",
		icon: "addressBook",
	},
	// {
	// 	url: "profile",
	// 	module: "profile",
	// 	icon: "profile",
	// },
	{
		url: "groups",
		module: "groups",
		icon: "groups",
	},
	{
		url: "calendar",
		module: "calendar",
		icon: "calendar",
	},
	{
		url: "articles",
		title: "База знаний",
		module: "knowl_base",
		icon: "library",
	},
	{
		url: "vacancies",
		module: "vacancies",
		icon: "inbox",
	},
	{
		url: "services",
		module: "services",
		icon: "settings",
	},
	{
		url: "polls",
		module: "polls",
		icon: "pie-chart",
	},
	{
		url: "chat",
		module: "chat",
		icon: "message-circle",
	},
	{
		url: "report_violation",
		module: "no_silent",
		icon: "meh",
	},
	{
		url: "idea",
		module: "ideas",
		icon: "zap",
	},
	{
		url: "employees",
		module: "employees",
		needAdmin: true,
		icon: "users",
	},
	{
		url: "shop",
		module: "shop",
		icon: "shop",
	},
	{
		url: "task-tracker",
		module: "task_tracker",
		icon: "settings",
	},
	{
		url: "adaptation",
		module: "adaptation",
		icon: "settings",
	},
	{
		url: "vacations",
		module: "vacations",
		icon: "settings",
		hide: true,
	},
	{
		url: "clients",
		module: "clients",
		needClient: false,
		needSuperAdmin: true,
		icon: "settings",
	},
	{
		url: "admin",
		title: "Админ.панель",
		module: "admin",
		needClient: false,
		needSuperAdmin: true,
		icon: "settings",
	},
]