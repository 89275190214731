<template>
  <div
    v-if="currentClient && user"
    class="col sidebar"
		:class="{ collapsed, hidden: $mq == 'phone' }"
  	:style="{ backgroundColor: bg_color, color: font_color }"
	>
    <div v-show="!collapsed" class="logo pointer">
      <a :href="getLogoUrl">
        <img width="220" :src="getLogoImage" class="logo" alt />
      </a>
    </div>
    <div class="col gap2 px2 py4" id="menu-container">
      <div
        v-for="item in menuItemsFiltered" :key="item.url"
        class="menu-item f-align-center pointer gap2"
        :class="{ active: activeSection == item.url }"
        :style="{ color: font_color }"
        @click="goToPage(item.url)"
      >
        <template v-if="collapsed">
          <a-tooltip placement="right" :title="$t('common.menu.'+item.module)">
            <!-- <img :src="getIcon(item.icon)" alt height="16" /> -->
            <SvgIcon :icon-name="item.icon" folder="sidebar" viewBox="0 0 16 16" />
          </a-tooltip>
        </template>
        <!-- Когда меню на всю ширину -->
        <template v-else>
          <!-- <img :src="getIcon(item.icon)" alt height="16" /> -->
          <SvgIcon :icon-name="item.icon" folder="sidebar" viewBox="0 0 16 16" :color="font_color" only-stroke />
          <div class="f-grow-1">
            {{ currentClient.item_names && currentClient.item_names[item.module] || $t('common.menu.'+item.module) }}
          </div>
          <!-- Какой то счетчик или бейдж -->
          <div></div>
        </template>
      </div>
    </div>    
  </div>
  <div v-else>Loading...</div>
</template>

<script>
import menuItems from './menuItems'
import { mapGetters, mapState } from "vuex"
// import { ENTER_CLIENT } from "@/store/client/actions.type" 

import mxCoreData from '@/mixins/userData'

export default {
  components: {

  },
  
	mixins: [ mxCoreData ],

  data() {
    return {
      menuItems,
      isActive: this.$route.path.split("/")[1],
      defaultLogo: require("@/assets/no_logo.png"),
    }
  },

  computed: {
    ...mapGetters(['activeSection','currentClient']),
    ...mapState('notifications', {
      getCountNotifs: state => state.unread || '',
    }),
		...mapState('common', {
			vendor: state => state.vendor,
		}),
    bg_color() {
      return this.isSuperAdmin ? "#43425d" : this.currentClient?.color || "#43425d"
    },
    font_color() {
      return this.currentClient?.fontColor || 'white'
    },
    menuItemsFiltered() {
      const sa = this.isSuperAdmin
      const adm = this.isAdmin
      const client_defined = !sa || !!this.currentClient
      // получаем номер позиции из массива фич клиента
      const featuresAndMore = [
        ...this.currentClient?.features,
        ...this.menuItems.filter(m => m.module == 'employees' || m.module == 'profile')
      ]
      const orders = Object.fromEntries(featuresAndMore.map((f, i) => [f.feature || f.module, f.order || i]))
      // т.к. массив menuItems это фиксированный список пунктов без позиций
      return this.menuItems.filter(item => 
        (item.needClient === false || client_defined) &&
        (!item.needSuperAdmin || sa) &&
        (!item.needAdmin || adm) &&
        item.hide !== true &&
        this.canUse(item.module))
            .map((item, i) => ({...item, order: orders[item.module]}))
            .sort((a, b) => a.order - b.order);
    },
    getLogoImage() {
			return this?.currentClient?.logo || this.defaultLogo
		},
    getLogoUrl() { 
      return this.currentClient?.logo_url || {
        yandex: 'http://connectable.site',
        google: 'http://intranetable.site'
      }[this.vendor]
    },
		userKey() {
			return this?.uid || "nouser"
		},
		collapsed() {
			return ["mobile", "tablet"].includes(this.$mq)
		},
  },

  methods: {
    // ...mapActions([ENTER_CLIENT]),
    goToPage(url) {
			if (this.$route.name != url) {
				const path = url == '/' ? '/' : `/${url}${(url == "profile" && "/" + this.userKey) || ""}`
				this.$router.push({ path })
			}
			this.isActive = url
		},
		getIcon(name) {
			return require(`@/assets/sidebar/${name}.svg`)
		},
		// определение что у текущего пользователя есть право на указанный модуль
		canUse(module_name) {
			// 0) модули, включенные без учета прав
			if (['profile', 'employees', 'clients', 'admin','notifications'].includes(module_name)) return true
			if (!this.currentClient) return false
			// 1) у клиента включен модуль
			const hasFeat =
				false || this.currentClient?.features.some(f => f.feature == module_name && f.active == true)
			// 2) у пользователя есть роль или он админ
			const canUse = this.isAdmin || this.isSuperAdmin || this.hasRole(module_name)

			return !!hasFeat && canUse
		},
  },

  // mounted() {
  //   const menuItems = document.getElementById('menu-container').childNodes
  //   menuItems.forEach(div => {
  //     const paths = div.firstChild.children
  //     paths.forEach(path => {
  //       path.setAttribute('stroke', 'red')
  //     })
  //   })
  // }, 
}
</script>

<style lang="stylus" scoped>
.sidebar
  overflow-x hidden
  height 100vh
  min-width 220px
  transition all 0.3s
  .logo
    min-height: 100px;
    width: 220px;
    object-fit: contain;
  &.collapsed
    min-width 65px
    transition all 0.3s
  &.hidden
    display none
  .menu-item
    min-height 40px
    padding 8px 16px
    border-radius 6px

    path
      fill: red
    // img
    //   margin-right 18px
    //   margin-left 22px
    &.active
      // border-left 4px solid #A1A3F5
      background rgba(255,255,255,0.1)
      transition background-color 0.3s ease-in
      img
        margin-left 18px
    .menu-badge
      background-color cornflowerblue
      padding 0px 6px
      font-size 12px
      border-radius 8px
      min-width 20px
      text-align center
      
</style>