import Vue from 'vue';
import { AxiosResponse, AxiosError } from 'axios';

import { ADD_FOLLOW_USER, DEL_FOLLOW_USER } from '@/store/user/mutations.type';

type subscribeData = {
    userID: string, // на кого подписываемся
    userName: string, // текущий сотрудник кто подписывается
    curentUserID: string,
    userEmail: string,
}

type responseData = {
    error? : string,
    result: string,
}

export const followUser = async (context: any, usersID: subscribeData) => {
    const {data}: AxiosResponse<string> = await Vue.axios.post(`api/follow`, usersID )
    context.commit(ADD_FOLLOW_USER, data);
    return data
};
export const unfollowUser = async (context: any, usersID: subscribeData) => {
    const {data}: AxiosResponse<string> = await Vue.axios.post(`api/unfollow`, usersID )
    context.commit(DEL_FOLLOW_USER, data);
    return data
};
