<template>
  <a-modal
    centered
    destroyOnClose
    :closable="closable"
    :mask-closable="maskClosable"
    :visible="value"
    :width="Number.isInteger(width) ? `${width}px` : width"
    :bodyStyle="{
      ...bodyStyle,
      padding: '24px',
      height: Number.isInteger(height) ? `${height}px` : height,
      maxHeight: Number.isInteger(maxHeight) ? `${maxHeight}px` : maxHeight,
      overflow,
    }"
    :footer="hasFooter ? $slots.footer : null"
    @cancel="close"
  >
    <div :slot="!titleInBody ? 'title' : undefined" class="f-align-center" :class="titleClass">
      <!-- Иконка "Назад" -->
      <SvgIcon v-if="backIcon"
        class="primary mr6 pointer"
        icon-name="chevron_big_left"
        @click="backAction"
      />
      <!-- Текст заголовка -->
      <div class="f-grow-1 mr8" :class="{'flex-center': titleCentered}">{{ $t(title) }}</div>
    </div>

    <div v-if="loading" class="backdrop-loader">
      <Loader />
    </div>
    <!-- Иконка "Закрыть" -->
    <SvgIcon slot="closeIcon" 
      class="primary"
      :class="{mt3: titleInBody}"
      icon-name="cross" width="16"
      viewBox="0 0 16 16"
      @click="close"
    />

    <slot />

    <div slot="footer" v-if="hasFooter">
      <div class="flex-between">
        <div class="d-flex">
          <slot name="extra-btns" />
          <Button v-if="deleting" icon="trash_full" link danger @click="$emit('delete')" text="common.delete"/>
        </div>
        <div class="flex-end gap4 f-grow-1">
          <Button secondary @click="close" :text="cancelProps.text || 'common.cancel'"/>
          <Button
            v-if="okProps.visible !== false"
            :loading="btnLoading"
            primary
            :disabled="okProps.disabled || false"
            :text="okProps.text || 'common.save'"
            @click="$emit('save')"
          />
        </div>
        
      </div>
      <slot name="subfooter">
        <!-- что-нибудь что нужно разместить под всеми кнопками -->
      </slot>
    </div>
    <div slot="footer" v-else>
      <!--  -->
    </div>
  </a-modal>
</template>

<script>
export default {
  components: {
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    // центрирование заголовка
    titleCentered: {
      type: Boolean,
      default: false,
    },
    // стили, которые будут применяться к заголовку
    titleClass: {
      type: [String, Object, Array],
      default: '',
    },
    // Заставляет заголовок выводиться не в слоте title а в теле, чтобы не было отделяющей линии
    titleInBody: {
      type: Boolean,
      default: false,
    },
    // Надо ли выводить иконку "Назад" слева от заголовка
    backIcon: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number],
      default: '600px',
    },
    height: {
      type: [String, Number],
      default: '',
    },
    maxHeight: {
      type: [String, Number],
      default: '',
    },
    overflow: {
      type: String,
      default: 'auto',
    },
    value: {
      type: Boolean,
      default: false,
    },
    // надо ли выводить кнопку "Удалить" в футере
    deleting: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    btnLoading: { // спинер для кнопки "Сохранить"
      type: Boolean,
      default: false,
    },
    okProps: {
      type: Object,
      default: () => ({}),
    },
    cancelProps: {
      type: Object,
      default: () => ({}),
    },
    closable: {
      type: Boolean,
      default: true,
    },
    maskClosable: {
      type: Boolean,
      default: true,
    },
    hasFooter: {
      type: Boolean,
      default: true,
    },
    bodyStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['input', 'back', 'delete','save'],
  data() {
    return {
      //
    }
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    backAction() {
      this.$emit('back')
    }
  },
}
</script>

<style lang="scss" scoped>
.backdrop-loader {
  position: absolute;
  width: 100%;
  z-index: 6;
  top: 0;
  left: 0;
  height: 100%;
  backdrop-filter: blur(1px);
  background-color: rgba(50,50,114,.2);
}
:deep(.ant-modal-close-x) {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>