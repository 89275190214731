import Vue from 'vue';

// import {
//   GET_MOODS,
//   SEND_MOOD,
// } from './actions.type'

import {
  ADD_MOOD,
  SET_MOODS,
  SET_METER_NEED
} from './mutations.type'
import { AxiosResponse } from 'axios';

type MoodResult = {
  result: any
}

export const getMoods = async (context: any, data: { client: string, range: string[]}) => {
  const url = `api/mood/stat/${data.client}/${data.range[0]}/${data.range[1]}`
  const res: AxiosResponse<MoodResult> = await Vue.axios.get(url)
  context.commit(SET_MOODS, res?.data?.result);
  return res?.data?.result
};

export const sendMood = async (context: any, data: any) => {
  const res: AxiosResponse<MoodResult> = await Vue.axios.post(`api/mood`, data)
  context.commit(ADD_MOOD, res?.data?.result);
};

export const getCheckingMood = async (context: any, user_id: string) => {
  type MoodChecking = {
    value: number,
    need: boolean,
  }
  const res: AxiosResponse<MoodChecking> = await Vue.axios.get(`/api/mood/check/${user_id}`)
  context.commit(SET_METER_NEED, res?.data?.need)
}