import Vue from 'vue';
import {
  ADD_ANSWER_FOR_COMMENT,
  ADD_COMMENT_FOR_POST,
  ADD_LIKE_FOR_COMMENT,
  ADD_LIKE_FOR_ANSWER,
  ADD_LIKE_FOR_POST,
  ADD_POST, CHANGE_POST, CHANGE_COMMENT, CHANGE_ANSWER,
  REMOVE_POST,
  REMOVE_COMMENT,
  REMOVE_ANSWER,
  SET_POST,
  SET_POSTS,
  SET_POSTS_OF_FOLLOWS,
  SET_POSTS_OF_GROUPS,
  SET_COMMENTS_FEED,
  SET_MENTIONS_FEED,
  SET_POST_VIEWED,
  SET_COMPANY_FEED,
  SET_USER_FEED,
  SET_NOTIFICATIONS_FEED,
} from '@/store/post/mutations.type';

import store from "@/store";
import { AxiosResponse, AxiosError } from 'axios';
import { UploadResponse, FileUploadedInfo, FeedRequest } from '../dataTypes'

const config = {
  onUploadProgress: function(progressEvent:any) {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    store.state.common.progress = percentCompleted
  }
}

// Main function for getting company feed
export const getCompanyFeed = async (context: any, data: FeedRequest) => {
  const response: AxiosResponse = await Vue.axios
    .get(`api/post/news/${data.client_id}/${data.page}`)
  context.commit(SET_COMPANY_FEED, response.data)
}

// Main function for getting user's personal feed
export const getUserFeed = async (context: any, data: FeedRequest) => {
  const response: AxiosResponse = await Vue.axios
    .get(`api/post/user_feed/${data.user_id}/${data.page}`)
  context.commit(SET_USER_FEED, response.data)
}

// подгрузка одного поста, например по сигналу с бэка
export const loadPost = async (context: any, post_id: string) => {
  const response: AxiosResponse = await Vue.axios.get(`api/post/${post_id}`)
  context.commit(ADD_POST, response.data.result);
  return response.data.result
}

type UploadError = {
  error: {
    code: string,
    text: string,
    bucketname: string,
  }
}
export const sendNewPost = async (context: any, post: any) => {
  if (!post.formData) {
    const response: AxiosResponse<{result: any}> = await Vue.axios.post('api/post', post)
    context.commit(ADD_POST, response.data.result);
    return response.data.result
  }
  // дальше для случая когда приложен файл
  store.state.common.progress = 0
  store.state.common.progressShow = true
  try {
    const { data } : AxiosResponse<UploadResponse> = await Vue.axios.post('api/upload', post.formData, config)
    const files = data.results.map((d:any) => ({ ...d.file, mini: d.thumb }))
      
    console.log('Файлы загружены', data);
    post.attachment = files;
    delete post.formData;
    
    const {data: postData}: AxiosResponse<{result: any}> = await Vue.axios.post('api/post', post)
    context.commit(ADD_POST, postData.result);
    return postData.result;
  } catch (error) {
    const err = error as AxiosError
    console.log('Ошибка загрузки файла', err.message);
    if (err.response) {
      const {code,bucketname} = (err.response.data as UploadError).error
      const msgs = {
        NoSuchBucket: "Нет бакета "+bucketname,
        accountDisabled: "Аккаунт заблокирован",
        ECONNREFUSED: "Сервер отказал в соединении" 
      }
      return {
        status: 522,
        error: err.message +': '+ msgs[code]
      }
    } else return {
      status: 522,
      error: err.message
    }
  }
  finally {
    // ждем полсекунды и закрываем
    setTimeout(() => { store.state.common.progressShow = false},1500)
  }
};

export const editPost = async (context: any, post: any) => {
  if (post.formData) {
    let response = await Vue.axios.post('api/upload', post.formData)
    post.attachment = [...post.attachment, ...response.data.results.map((d:any) => d.file)]
    delete post.formData;
  }
  const response = await Vue.axios.put('api/post/' + post._id, post)
  return context.commit(CHANGE_POST, response.data.result);
};

export const editComment = async (context: any, comment: any) => {
  const {data} = await Vue.axios.put('api/comment/' + comment._id, comment)
  context.commit(comment.status ? CHANGE_ANSWER : CHANGE_COMMENT, data.result);
  return data.result
};

export const repost = async (context: any, post: any) => {
  const response = await Vue.axios
    .post('api/post', post);
  return;
};

export const getPosts = async (context: any, parent: any) => {
  context.commit(SET_POSTS, []);
  try {
    const response = await Vue.axios.get('api/post', { params: parent });
    context.commit(SET_POSTS, response.data.result);
  } catch (error) {
    console.log(`getPosts error: ${error}`);
  }
};

export const getAllPosts = async (context: any, client_id: string) => {
  try {
    const posts = await Vue.axios
      .get('/api/post/client/' + client_id)
    context.commit(SET_POSTS, posts.data.result)
  } catch (error) {
    console.log(`getAllPosts error: ${error}`);
  }
}

export const getPostsOfFollows = async (context: any, user_id: string) => {
  const posts = await Vue.axios
    .get('/api/post/follows/' + user_id)
  context.commit(SET_POSTS_OF_FOLLOWS, posts.data.result)
}

export const getPostsOfBlog = async (context: any, user_id: string) => {
  const posts = await Vue.axios
    .get('/api/post/user/' + user_id)
  context.commit(SET_POSTS, posts.data.result)
}

export const getPostsOfGroup = async (context: any, group_id: string) => {
  const posts = await Vue.axios
    .get('/api/post/group/' + group_id)
  context.commit(SET_POSTS, posts.data.result)
}

export const getPostsOfuserGroups = async (context: any, user_id: string) => {
  const posts = await Vue.axios
    .get('/api/post/group/user/' + user_id)
  context.commit(SET_POSTS_OF_GROUPS, posts.data.result)
}


/**
 * Получение текущего поста (когда открывают в отдельном окне)
 * @param context контекст стора для доступа к commit
 * @param postId код поста
 */
export const getPost = async (context: any, postId: any) => {
  const response = await Vue.axios
    .get(`api/post/${postId}`);
  context.commit(SET_POST, response.data.result);
};

/**
 * Обновление (перечитываение поста) с сервера и помещение в стор
 * @param context контекст стора для доступа к commit
 * @param postId код поста
 */
export const updatePost = async (context: any, postId: any) => {
  const response = await Vue.axios
    .get(`api/post/${postId}`);
  context.commit(CHANGE_POST, response.data.result);
};

type Like = {
  where: 'blog' | 'company' | 'feed' | 'group',
  like_type: 'post' | 'comment' | 'answer' // what was liked
  id: string, // id of liked object
  post_id: string, // id of post where like has been 
  user_id: string // who has liked 
}

type LikeResponse = {
  _id: string,
  author: {
    _id: string,
    firstName: string,
    lastName: string
  },
  created: Date
}

/**
 * 
 * @param context 
 * @param like : Like  // TODO: доработать на стороне клиента отправку 
 */
export const sendLike = async (context: any, like: Like) => {
  const response = await Vue.axios.post('api/post/like', like);
  if (response.data.status == 201) {
    const newLike = response.data.result;
    if (like.like_type === 'post') {
      context.commit(ADD_LIKE_FOR_POST, newLike);
    }
    if (like.like_type === 'comment') {
      context.commit(ADD_LIKE_FOR_COMMENT, newLike);
    }
    if (like.like_type === 'answer') {
      context.commit(ADD_LIKE_FOR_ANSWER, newLike);
    }
  }
};

export const sendComment = async (context: any, comment: any) => {
  const response: AxiosResponse = await Vue.axios.post('api/comment', comment)
  const newComment = response.data.result;
  if (newComment.parent.type === 'post') { context.commit(ADD_COMMENT_FOR_POST, newComment); }
  if (newComment.parent.type === 'comment') { context.commit(ADD_ANSWER_FOR_COMMENT, newComment); }
};

type delPostData = {
  _id: string,
  where: string,
}

export const deletePost = async (context: any, data: delPostData) => {
  const response: AxiosResponse = await Vue.axios.delete('api/post/' + data._id)
  const res = {
    post_id: response.data.result,
    where: data.where
  }
  context.commit(REMOVE_POST, res);
};

export const deleteComment = async (context: any, comment: any) => {
  const response = await Vue.axios.delete(`api/comment/${comment._id}`)
  if (response.status == 201) {
    if (comment.type) {
      context.commit(REMOVE_ANSWER, comment);
    } else {
      context.commit(REMOVE_COMMENT, comment);
    }
  } else {
    console.log('Deleting was not performed, status ', response.status);
  }
};

export const getComments = (context: any, user_id: string) => {
  return Vue.axios
    .get('/api/post/comments_feed/' + user_id)
    .then((response: any) => {
      context.commit(SET_COMMENTS_FEED, response.data)
    })
}

export const getMentions = (context: any, user_id: string) => {
  return Vue.axios
    .get('/api/post/mentions_feed/' + user_id)
    .then((response: any) => {
      context.commit(SET_MENTIONS_FEED, response.data)
    })
}

enum WherePost {
  company = 'company',
  feed = 'feed',
  group = 'group',
  notifications = 'notifications'
}

// Типизирование запроса и ответа с бэка
type postViewData = {
  _id: string,
  where: WherePost,
  user_id: string,
}
type postViewResult = {
  post_id: string,
  where: WherePost,
  view: {
    time: string,
    user: string,
    _id: string,
  }
}

export const viewPost = async (context: any, data: postViewData) => {
  const url = `/api/post/${data._id}/view`
  const payload = {user_id: data.user_id, where: data.where}
  try {
    const res : AxiosResponse<postViewResult> = await Vue.axios.put<postViewResult>(url, payload)
    context.commit(SET_POST_VIEWED, res.data)
    return res.data
  } catch (error) {
    if (Vue.axios.isAxiosError(error) && error.response) {
      return {
        error: error.message,
        stack: error.stack,
        data: error.response.data,
      }
    } else {
      return {
        error
      }
    }
  }
  
}