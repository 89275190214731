import Vue from 'vue';
import { AxiosError, AxiosResponse } from 'axios';

import { 
  SET_SHOP, 
  SET_SHOP_ITEMS, UPD_SHOP_ITEM, ADD_SHOP_ITEM, REMOVE_SHOP_ITEM, 
  SET_ACHIEVES, UPD_ACHIEVE, ADD_ACHIEVE, REMOVE_ACHIVE, SET_BALANCE, SET_BALANCES, 
  SET_USER_ACHIEVES, SET_STATISTIC, SET_ITEM_REST, DEL_STAT_ITEM,
} from './mutations.type';
import { ACCRUE_COINS, DEL_ACHIEV, DEL_SHOP_ITEM } from './actions.type';

import {  Statistics, Stat, Balance, FullBalance, BuyData, BuyResult, AccrueData, AccrueResult } from './types';

// =================================================================
// Универсальные функции получения и изменения данных
async function getObjByClient(name, client_id, context, mut?) {
  const response: AxiosResponse<{result: any}> = await Vue.axios.get(`api/${name}/byClient/${client_id}`)
  if (mut) context.commit(mut, response.data);
  return response.data
}
// Добавление нового объекта в БД
async function newObject(name, data, context, mut) {
  const response: AxiosResponse<{result: any}> = await Vue.axios.post(`api/${name}`, data)
  context.commit(mut, response.data);
  return response.data
}
// Изменение объекта
async function editObject(name, data, context, mut) {
  const response: AxiosResponse<{result: any}> = await Vue.axios.put(`api/${name}/${data._id}`, data)
  context.commit(mut, response.data);
  return response.data
}

// СУБ-ОБЪЕКТЫ, например товары в модуле "Магазин"
// Получение суб-объектов в разделе
async function getSubObjects(moduleName, name, client_id, context, mut) {
  const response: AxiosResponse<{result: any}> = await Vue.axios.get(`api/${moduleName}/${client_id}/${name}`)
  context.commit(mut, response.data);
  return response.data
}
// Добавление нового суб-объекта в БД
async function newSubObject(moduleName, name, data, context, mut) {
  const response: AxiosResponse<{result: any}> = await Vue.axios.post(`api/${moduleName}/${data.client_id}/${name}`, data)
  context.commit(mut, response.data);
  return response.data
}
// Изменение объекта
async function editSubObject(moduleName, name, data, context, mut) {
  const response: AxiosResponse<{result: any}> = await Vue.axios.put(`api/${moduleName}/${data.client_id}/${name}`, data)
  context.commit(mut, response.data);
  return response.data
}

type DeleteResult = {
  ok: boolean,
  message: any,
}
// Удаление объекта
async function delSubObject(moduleName, name, _id, context, mut) {
  const response: AxiosResponse<DeleteResult> = await Vue.axios.delete(`api/${moduleName}/${name}/${_id}`)
  context.commit(mut, _id);
  return response.data
}
// =================================================================

// Getting All records for all types of objects
// Получение магазина по коду клиента
export const getShop = async (context: any, client_id: string) => {
  const shop = await getObjByClient('shop', client_id, context)
  context.commit(SET_SHOP, shop[0])
}
// Получение достижений по коду клиента
export const getAchievements = async (context: any, client_id: any) => {
  return await getSubObjects('shop', 'achievements', client_id, context, SET_ACHIEVES)
}
// Получение товаров по коду клиента
export const getShopItems = async (context: any, data: {client_id: string, sort: string, order: string}) => {
  const url = `api/shop/${data.client_id}/shop_items?sort=${data.sort}&order=${data.order}`
  const response: AxiosResponse<{result: any}> = await Vue.axios.get(url)
  context.commit(SET_SHOP_ITEMS, response.data);
  return response.data
}

// Сохранение данных
// Магазины:
export const saveShop = async (context: any, shop_data: any) => {
  // пока не будет картинки валюты
  // if (!shop_data.formData) {
    // PUT чтобы не срабатывал стандартный CRUD модуль
    const response: AxiosResponse<{result: any}> = await Vue.axios.put(`api/shop`, shop_data)
    context.commit(SET_SHOP, response.data);
    return response.data
  // }
}

// ----------------------------------------------------------------
// Add objects
export const addAchievment = async (context: any, achievement: any) => {
  if (achievement.picData) {
    const { data } = await Vue.axios.post('api/upload', achievement.picData)
    achievement.pic = data?.results[0].file.src
    delete achievement.picData;
  }
  const res = await newSubObject('shop','achievements', achievement, context, ADD_ACHIEVE)
  return res
}

export const addShopItem = async (context: any, item: any) => {
  if (item.picsData) {
    const { data } = await Vue.axios.post('api/upload', item.picsData)
    item.pics = data?.results.map((d:any) => d.file.src)
    delete item.picsData;
  }
  return await newSubObject('shop','shop_items', item, context, ADD_SHOP_ITEM)
}

// Edit objects
export const editAchievment = async (context: any, achievement: any) => {
  if (achievement.picData) {
    const { data } = await Vue.axios.post('api/upload', achievement.picData)
    achievement.pic = data?.results[0].file.src
    delete achievement.picData;
  }
  return await editSubObject('shop','achievements', achievement, context, UPD_ACHIEVE)
}

export const editShopItem = async (context: any, item: any) => {
  if (item.picsData) {
    const { data } = await Vue.axios.post('api/upload', item.picsData)
    const pics = item.pics || []
    item.pics = [...pics, ...data?.results.map((d:any) => d.file.src)]
    delete item.picsData;
  }
  // изменяем данные о товаре
  const _item = await editSubObject('shop','shop_items', item, context, UPD_SHOP_ITEM)
  // запрашиваем актуальный остаток с учетом продаж
  const {data}: AxiosResponse<{rest: number}> = await Vue.axios.get('api/shop/item_rest/' + item._id)
  // устанавливаем остаток
  context.commit(SET_ITEM_REST, {item_id: item._id, rest: data.rest})
  return {
    ..._item, 
    amount: data.rest // замещаем остаток видимый клиенту
  }
}

// Delete object
export const delAchievment = async (context: any, _id: string) => {
  return await delSubObject('shop','achievements', _id, context, REMOVE_ACHIVE)
}
export const delShopItem = async (context: any, _id: string) => {
  return await delSubObject('shop','shop_items', _id, context, REMOVE_SHOP_ITEM)
}

// Начисление монет за достижения
export const accrueCoins = async (context: any, data: AccrueData) => {
  const response: AxiosResponse<AccrueResult> = await Vue.axios.post(`api/shop/accrue`, data)
  // нет мутации т.к. начисление отражается только в статистике 
  return response.data
}

// Отмена начисления или награды (откат операции)
export const delAwardCoins = async (context: any, op_id: string) => {
  const response: AxiosResponse<DeleteResult> = await Vue.axios.delete(`api/shop/accrue/${op_id}`)
  context.commit(DEL_STAT_ITEM, op_id)
  return response.data
}

// Покупка товарной позиции
export const buyItem = async (context: any, data: BuyData) => {
  const response: AxiosResponse<BuyResult> = await Vue.axios.post(`api/shop/buy`, data)
  // в ответе нет инфы об остатке, поэтому минусуем вручную в сторе
  context.commit(SET_ITEM_REST, response.data);
  return response.data
}

// Get balance
export const getBalance = async (context: any, uid: string) => {
  const response: AxiosResponse<Balance> = await Vue.axios.get(`api/shop/balance/${uid}`)
  context.commit(SET_BALANCE, response.data.amount);
  return response.data
}


// Получить все балансы
export const getAllBalances = async (context: any, client_id: string) => {
  const response: AxiosResponse<FullBalance[]> = await Vue.axios.get(`api/shop/balances/${client_id}`)
  context.commit(SET_BALANCES, response.data);
  return response.data
}

// добавить количество к остатку. 
// Добавление происходит на сервере, 
// т.к. нельзя послать через editSubObject сумму текущего количества и добавляемого, т.к. текущее может устареть
export const addItemsAmount = async (context: any, data: {id: string, amount: number}) => {
  const response: AxiosResponse = await Vue.axios.post(`api/shop/shop_items/${data.id}/add/${data.amount}`)
  context.commit(UPD_SHOP_ITEM, response.data);
  return response.data
}

// это как баланс только для получения ачивок
export const getUserAchievements = async (context: any, uid: string) => {
  const response: AxiosResponse<Balance> = await Vue.axios.get(`api/shop/balance/${uid}`)
  context.commit(SET_USER_ACHIEVES, response.data.ach);
  return response.data
}

// Get statistics
export const getStatistics = async (context: any, data: Stat) => {
  const response: AxiosResponse<Statistics> = await Vue.axios.post(`api/shop/stats`, data)
  context.commit(SET_STATISTIC, response.data);
  return response.data
}
