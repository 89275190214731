<template>
  <div class="user-bar col py2 px4" :class="{mobile: $mq == 'phone'}">
    <div class="user-info flex-between" v-if="user && $mq!='phone'" >
      <div class="f-align-top gap4">
        <div class="client-name bold f-align-center f-grow-1">
          {{ getClientInfo() }}
          <span v-if="isSuperAdmin" style="margin-left: 10px"> mq:{{$mq}} </span>
          <span class="system-text" v-if="currentClient && debug_mode">
            Socket
            {{!$socket.client.connected ? "not connected" : "connected to " + $socket.client.nsp}}
          </span>          
        </div>
        <EventsNotifier />
        <div class="test-panel" v-if="currentClient && debug_mode">
          <a-button size="small" @click="testSocketServer">SEND TO ALL</a-button>
          <a-button size="small" @click="pingSocketServer">PING</a-button>
          <a-alert
            :message="pong.msg"
            :type="pong.type"
            banner
            v-if="pong.show"
          />
          <a-spin style="max-height: 20px" v-else />
          <div class="system-text">
            {{$t('common.login_bar.show_msg_in_console')}}
            <a-switch size="small" @change="(chkd) => this.showPong = chkd" />
          </div>
          <div class="system-text">
            last 'pong': {{ lastPong && lastPong.toLocaleTimeString() }}
          </div>
          <!-- <a-button size="small" @click.prevent="playSound">Beep</a-button> -->
          <router-link to="/no-such-page">404 here ->></router-link>
        </div>        
      </div>
      <!-- Меню действий пользователя -->
      <div class="f-between-center gap2">
        <!-- <ThemeSwitcher /> -->
        <!-- Аватарка -->
        <Balance />
        <Button v-if="!isSuperAdmin"
          secondary round small-icon :icon="unreadNotifications ? 'notification_dot' : 'notification'"
          :badge="unreadNotifications" 
          badge-color="red"
          @click="showNotifications"
        />
        <!-- <Button secondary round small-icon icon="settings_filled"  /> -->
        <Avatar :person="user" />

        <Dropdown
          right
          :list="[
            {text: 'profile.open', svg_icon: 'user', fn: () => $router.push('/profile/'+uid)},
            {text: 'common.login_bar.logout', svg_icon: 'log_out', fn: LOGOUT },
          ]"
        >
          <Button link :text="user.firstName +' '+ user.lastName" icon="chevron_down" />
        </Dropdown>
        <!-- <Dropdown
        
        >

          <a-menu slot="overlay">
            <a-menu-item key="logout" @click="LOGOUT">
              <a-icon type="logout" />{{$t('common.login_bar.logout')}}
            </a-menu-item>
          </a-menu>
          <a-button class="logout" style="margin-right: 1rem">
            {{ user.firstName }} {{user.lastName}}
            <a-icon type="down" />
          </a-button>
        </Dropdown> -->
      </div>
    </div>
    <!-- Окно  -->
    <!-- <ConnectionInfoModal
      :connected="connModal.connected"
      :visible="connModal.visible"
      :timeCounter="noConnectionTime"
    /> -->

    <div v-if="$mq == 'phone'" class="flex-between">
      <div>
        <img :src="currentClient?.logo || defaultLogo" class="logo" alt style="height: 24px" />
      </div>
      <!-- <div class="d-flex">
        <Button icon="comments" size="small" />
        <Button icon="plus_circle_outline" size="small"/>
        <Button icon="notification_dot" size="small"/>
      </div> -->
    </div>
    
    <LoadingProgress />

    <NotificationPopup v-if="uid" ref="notifDialog" />
    
    <audio id="beep" src="@/assets/sounds/notify.mp3" />
    <audio id="audio" src="@/assets/sounds/guess-what.mp3" />
    <audio id="coin-drop" src="@/assets/sounds/coin-drop.mp3" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import { LOAD_POST } from "@/store/post/actions.type";
import { LOGOUT, GET_INFO_ABOUT_ME } from "@/store/user/actions.type";
import { ENTER_CLIENT, GET_NOTIF_TEMPLATES, GET_JOURNAL } from "@/store/client/actions.type";
import { GET_SHOP, GET_BALANCE } from "@/store/shop/actions.type";
import { GET_GROUPS_USER_CAN_READ } from "@/store/group/actions.type";

import { GET_ONE_NOTIFICATION, GET_UNREAD_COUNT, GET_TMPL_NOTIFICATIONS } from "@/store/notifications/actions.type";
import { ADD_NOTIFS_COUNT } from "@/store/notifications/mutations.type";

import { SET_SHOW_IMAGE_HEADER } from "@/store/shower/mutations.type";
import { SET_CURRENT_CLIENT, ADD_JOURNAL_REC } from "@/store/client/mutations.type";

import EventsNotifier from '@/modules/Calendar/components/panel-notifier'
import LoadingProgress from '@/components/common/LoadingProgress'

import { $notif } from '@/helpers'
import * as common from '@/components/common/logic'

import UINotifs from '@/services/UINotifs'
const DEFAULT_CHANNEL_SETTINGS = {
  system: true,
  email: true,
  notif_center: true,
  tg: false,
}

import defaultLogo from "@/assets/no_logo.png"
import mxCoreData from '@/mixins/userData'

export default {
  components: {
    // ConnectionInfoModal,
    LoadingProgress,
    EventsNotifier,
    NotificationPopup: () => import('@/modules/Notifications/components/NotificationPopup.vue'),
    Dropdown: () => import('@/components/common/Dropdown.vue'),
    ThemeSwitcher: () => import('@/components/common/ThemeSwitcher.vue'),
    Balance: () => import('@/modules/Shop/components/Balance.vue'),
  },

	mixins: [ mxCoreData ],
  
  data() {
    return {
      current: 1,
      debug_mode: false,
      audio: null,
      showPong: false,
      pong: { show: true, type: "success", msg: "..." },
      connection: null,
      waiter: null,
      lastPong: null,
      noConnectionTime: 0,
      connModal: { connected: true, visible: false },
      pingTimer: null,
      checkTimer: null,
      defaultLogo,
      /*************************************** 
       * Структура объекта "datauser":
       * "result": {
            "positions": [],
            "followers": [],
            "followersEmail": [],
            "_id": "5e92702c807aa5006245cb5d",
            "googleId": 108......,
            "googleToken": "ya29.a0Ae4lvC0y_l...",
            "lastName": "Vidyakin",
            "firstName": "Sergey",
            "email": "vidyakin.sergey@gmail.com",
            "googleImage": "https://lh3.googleusercontent.com/a-/AOh14GiyLlRfNQLdCsrLGZrG5eIgVeW5IyM2ZlAaQmSyaQ=s96-c",
            "password": "",
            "__v": 0
        },
        "iat": 1586995323,
        "exp": 1587081723
       */
    };
  },
  computed: {
    ...mapGetters([
      'uid',
      "users",
      'user',
      "groups",
      "currentClient",
      "groups_available",
      'clientFeatureFlags',
      'userData',      
    ]),
    // настройки по отдельным шаблонам
    ...mapGetters('notifications', ['client_popups_templ_settings','user_popups_templ_settings']),
    ...mapState('notifications', {
      all: state => state.all,
    }),
    ...mapState({
      // настройки каналов (не отдельных шаблонов)
      client_notif_channels: state => state.client?.currentClient.notif_settings || DEFAULT_CHANNEL_SETTINGS,
      user_notif_channels: state => state.auth.userData?.notif_settings 
                                || state.client?.currentClient.notif_settings || DEFAULT_CHANNEL_SETTINGS,
    }),
    unreadNotifications() {
      return this.all.filter(a => !a.views.some(v => v.user == this.uid)).length
    },
  },
  methods: {
    ...mapActions([LOGOUT,GET_INFO_ABOUT_ME,ENTER_CLIENT,GET_NOTIF_TEMPLATES,GET_GROUPS_USER_CAN_READ,LOAD_POST,GET_SHOP,GET_BALANCE,GET_JOURNAL]),
    ...mapActions('notifications', [GET_ONE_NOTIFICATION, GET_UNREAD_COUNT, ADD_NOTIFS_COUNT, GET_TMPL_NOTIFICATIONS]),
    ...mapMutations('notifications', [ADD_NOTIFS_COUNT]),
    ...mapMutations([ADD_JOURNAL_REC]),

    playSound(audio = 'common') {
      //const audio = new Audio("@/assets/sounds/guess-what.mp3");
      this.audio[audio].play();
    },
    pingSocketServer() {
      // функция показа ответа
      const showPong = (type = "success", msg = "PONG") => {
        this.pong = { type, msg, show: true };
      };

      // сбрасываем время и отправляем пинг-запрос
      this.lastPong = new Date();
      this.$socket.client.emit("PING", { beat: 1 }, (resp) => {
        this.lastPong = new Date();
        showPong();
        console.log(`PONG! ${this.lastPong.toLocaleString()}`);
      });
      // ждем ответа 3 секунды
      const checkTime = 3000;
      setTimeout(() => {
        const now = new Date();
        const diff = now - this.lastPong;
        console.log(
          `Server connection checked at ${new Date().toLocaleTimeString()}, diff is ${diff}ms`
        );
        if (diff >= checkTime) {
          showPong("error");
          this.$socket.client.disconnect();
          this.$socket.client.connect(`${this.apiURL}`);
          console.log(`Соединение ${this.apiURL} должно перезапуститься...`);
        } else {
          showPong("success", `last pong: ${diff}ms`);
        }
      }, checkTime);

      return;

    },
    testSocketServer() {
      this.$socket.client.emit(
        "FOR_ALL",
        {
          area: "ALL",
          msg: "Тестовое оповещение на всех сотрудников",
        },
        (resp) => {
          console.log(`Server responsed on FOR_ALL: ${resp.msg}`);
        }
      );
    },
    getSubdomain() {
      return window.location.href
        .replace(/https?:\/\//, "")
        .split("/")[0]
        .split(".")[0];
    },
    getClientInfo() {
      if (this.currentClient?.settings?.showClientTitle) return this.currentClient.name;
    },
    // установка счетчика сообщений из дочернего компонента
    // setNtfCounter(n) {
    //   this.notifCount = n;
    // },
    closeImage() {
      this.$store.commit(SET_SHOW_IMAGE_HEADER, false);
    },
    fillNotificationFeed() {
      this.notifs = this.messages.map((msg) => ({
        title: "Новая группа", // TODO: подстроить под разные типы сообщений
        html: msg.text,
      }));
    },
    showNotifications(el) {
      this.$refs.notifDialog.showDialog(el)
    },
    loadNotification(payload) {
      this.GET_ONE_NOTIFICATION(payload)
      this.ADD_NOTIFS_COUNT(1)
    },
  },
  async created() {
    const client_id = this.workspace
    const proms = [
      this.GET_INFO_ABOUT_ME()
    ]
    if (client_id) {
      proms.push(this.GET_NOTIF_TEMPLATES(client_id))
      proms.push(this.GET_UNREAD_COUNT({client_id, user_id: this.uid}))
      proms.push(this.GET_TMPL_NOTIFICATIONS({client_id}))  // для компании
      proms.push(this.GET_TMPL_NOTIFICATIONS({client_id, user_id: this.uid})) // для сотрудника
      if (this.clientFeatureFlags.shop) {
        await this.GET_SHOP(client_id)
      }
    }
    await Promise.all(proms)
    
    // определяем неймспейс
    let nsp = "/";
    // Если клиент не определен и пользователь - не суперадмин , то разлогиниваемся
    if (!this.currentClient) {
      if (!this.hasRole("superadmin")) {
        await this.ENTER_CLIENT(this.user.client_id);
        nsp = "/" + this.user.client_id;
      } else {
        const currentClient = JSON.parse(localStorage.getItem("currentClient"));
        if (currentClient) {
          this.$store.commit(SET_CURRENT_CLIENT, currentClient);
          nsp = "/" + currentClient.workspace;
        }
      }
    } else {
      nsp = "/" + client_id;
    }
    //console.log(`nsp ${nsp}`);
    if (nsp !== "/") {
      this.$socket.client.nsp = nsp;
      if (this.$socket.connected) {
        this.$socket.client.disconnect();
      }
      this.$socket.client.connect(`${this.apiURL}`);
      //console.log(`created and connecting...`);
    }
  },
  async mounted() {
    // this.$socket.client.on("socketMessage", (payload) => {
    //   console.log(`socket send somthing`);
    // });
    this.audio = {
      common: document.getElementById("audio"),
      coins: document.getElementById("coin-drop"),
    };
  },
  sockets: {
    // pong() {
    //   this.lastPong = new Date();
    // },
    connect() {
      this.lastPong = new Date();
      console.log("socket connected:", this.$socket.client.id);
    },
    pong(lag) {
      const now = new Date();
      const diff = now - this.lastPong;
      this.lastPong = now;
      if (this.showPong) {
        console.log(`PONG: ${this.$socket.connected} ${lag}`);
      }
      this.pong = {
        show: true,
        type: "success",
        msg: `last pong: ${diff > 100000 ? 0 : diff}ms, PING ${lag}ms`,
      };
    },
    connect_error(err) {
      console.log(`Connection error...`, err);
    },
    disconnect() {
      this.pong.type = "error";
      this.pong.msg = "Connection lost";
      //this.connModal = { visible: true, connected: false };
      console.log('socket disconnected!');
    },
    /**
     * Событие на прием сообщения с кодом "backendMessage"
     * @param payload: object - {action, msg, data}
     *  action - тип сообщения для разделения логики и универсальности
     *  msg - сообщение для отправки
     *  data - данные, структура зависит от типа сообщения
     */
    async backendMessage(payload) {
      const uid = this.uid
      // upd = данные об обновлении {
      //  module - какой модуль стора обновляем,
      // _id - код объекта который надо запросить с бэка
      // }
      const { action_type, upd, action, tmpl, title, msg, data } = payload
      const i_can_see = payload.whoSee?.length && payload.whoSee.includes(uid)
        
      // подгрузка данных для разных модулей
      if (action_type == 'DATA_UPDATE') {
        if (i_can_see) {
          console.log(`Необходимо обновить ${upd.module}`, payload);
          // Получение уведомлений (которое по сути пост с post_id)
          if (upd.module == 'notifications') {
            this.GET_ONE_NOTIFICATION({
              post_id: upd._id,
              action,
            })
          }
          // Обновление баланса при начислении и отмене
          if (upd.module == 'shop') {
            if (action == 'UPD_BALANCE') {
              await this.GET_BALANCE(uid)
            }          
          }
        }
        
        // другие модули тут с другими экшенами стора
      }
      // Действия с логами
      else if (action_type == 'LOGS') {
        if (action == 'ADD') this.ADD_JOURNAL_REC(data)
        if (action == 'UPDATE') this.GET_JOURNAL(this.workspace)
      }
      // дальше надо продолжить ветку и добавить типы "показать попап" и еще какие нибудь
      // ... но пока свалим все в "else" 
      else { 
        // унифицированный модуль показа сообщений
        UINotifs.backendMessage(this, { action, tmpl, title, msg, data })
      } 
      
      // Прочие действия не относящиеся к сообщениям
      if (action == "GROUPS_NEW" || action == "GROUP_DELETED") {
        await this.GET_GROUPS_USER_CAN_READ(uid);
      }
      if (action.substr(0,7) == 'EVENTS_') {
        // this.loadNotification(payload)
      }
      // Общие действия если пользователь должен это видеть
      if (i_can_see) {
        if (action == 'SHOP_TAKEN_MONEY' || action == 'SHOP_CANCEL_MONEY') await this.GET_BALANCE(uid)
      }
    },

    async socketMessage(payload) {
      // проверяем доступ к разделам и выходим если нет прав
      const access = { 
        groups: this.hasRole('groups') || this.isAdmin
      }
      const { area, action } = payload;
      console.log('LoginBar socket data: ', payload)
      if (!common.hasAccess(access, area)) return
      // =======================================================================
      const messages = {
        UPDATE_FEED: this.$t('common.notifs.feed.need_update'),
        FOR_ALL: "ВСЕМ ВСЕМ ВСЕМ",
      };
      
      // helpers
      const fioByID = (id) => {
        const user = this.users.find((u) => u._id == id);
        return user && user.firstName + " " + user.lastName;
      };
      const groupNameByID = (id) => {
        const gr = this.groups_available.find((g) => g._id == id);
        return gr && gr.name;
      };
      // являюсь ли я подписчиком сотрудника с указанным id
      const IAmFollowerOf = (id) => {
        const user = this.users.find((u) => u._id == id);
        return user.followers?.includes(this.uid);
      };
      let sound = 'common' // тип звука, этот по дефолту
      // здесь только оповещения, обновления данных в самих компонентах
      if (area == "FORCE_LOGOUT") {
        console.log('FORCE_LOGOUT', payload.user_id == this.uid, payload.client_id == this.workspace);
        if (payload.user_id == this.uid || payload.client_id == this.workspace) {
          this.playSound();
          this.LOGOUT();
        }
      } else if (area == "ALL") {
        $notif(this, "info", "Тестовое оповещение", `${payload.msg}`);
      } else if (area == "USERS") {
        if (payload.action == "updated") {
          // команда что текущий пользователь изменен кем-то извне, надо переполучить свои данные с сервера
          if (this.uid == payload.user_id) {
            await this.GET_INFO_ABOUT_ME();
            const msg = this.$t(`empls.rights_${payload.status ? 'add': 'rem'}`) 
                      + this.$t(`empls.rights_chng.${payload.role}`)
            $notif(this, "info", 'empls.rights_change', msg);
          }
        }
      } else if (area == "FUNCS") {
        console.log("Funcs "+payload.feature+" was "+ payload.action);
        
        // если совпадает клиент - т.е. у всех пользователей клиента должна обновиться инфа
        if (this.currentClient._id == payload.client_id) {
          await this.ENTER_CLIENT(this.currentClient.workspace);
          const msg = this.$t(`empls.funcs_${payload.action == 'set_on' ? 'on' : 'off'}`) 
                    + this.$t(`empls.funcs.${payload.feature}`)
          $notif(this, "info", "Изменение возможностей", msg)
        }
      } else if (area == 'CHAT') {
        if (payload.action == 'message') {
          const {isChat, members, roomName } = payload
          if (members.includes(this.uid)) {
            this.$notification.info({
              message: this.$t('chat.new_msg', {name: roomName}),
              placement: "topRight",
            });
            this.playSound();
          }
          return // сообщения обрабатывать??
        }
        // если чат начат не с текущим юзером
        if (payload.action == 'chat_new' && payload.empl != this.uid) return
        // если задан список получателей сообщения и юзера в нем нет
        if (payload.empls && !payload.empls.includes(this.uid)) return
        const message = {
          room_invite: `${this.$t('chat.you_added')}: ${payload.roomName}`, // это сообщение показывает компонент LoginBar
          chat_new: this.$t('chat.private_started', { with: payload.from }),
          room_deleted: this.$t('chat.room_deleted', { room: payload.roomName }),
        }[payload.action]
        if (message) $notif(this, "info", message);
      } else {
        return;
      }
      this.playSound(sound);
    },
  },
  watch: {
    currentClient(val) {
      if (!val) return;
      this.$socket.client.nsp = "/" + val.workspace;
      this.$socket.client.connect(`${this.apiURL}`);
      console.log(`socket connected to ${val.workspace}`);
    },
  },
};
</script>

<style lang="scss">
.test-panel {
  display: flex;
  align-items: center;
  margin-left: 50px;
  & > div,
  button {
    margin-right: 10px;
  }
}

.user-bar {
  // height: 50px;
  position: relative;
  justify-content: center;
  background-color: var(--bg-color); // тут будет корпоративный цвет
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
  &.mobile {
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0;
  }
  @media (max-width: 420px) {
    height: 48px;
  }
  .login {
    margin-right: 0.5rem;
    display: flex;
    justify-content: flex-end;

    .ant-btn {
      border: 0;
    }
  }

  .logout {
    border: none;
    padding: 0;
    height: auto;
    position: relative;
    // padding-left: 10px;
    // margin-left: 15px;
  }
  
  .user-info {
    line-height: 32px;
    // @media (min-width: 320px) {
    //   padding: 0.5rem;
    //   flex-direction: column;
    // }
    // @media (min-width: 640px) {
    //   flex-direction: row;
    //   padding: 0 16px;
    // }
    &-name {
      line-height: 3.125rem;
    }
    .name-and-events {
      display: flex;
      
      .client-name {
        font-size: 12pt;
        margin-right: 30px;
        .system-text {
          font-size: 10pt;
          font-weight: normal;
        }

        @media (max-width: 760px) {
          margin-right: 30px;
        }
      }
    }
    // i {
    //   line-height: 0;
    //   margin-right: 0.5rem;
    //   margin-left: 0.5rem;
    // }
    .ant-avatar {
      /*margin-top: 0.375rem;*/
      margin-top: 0;
      margin-right: 0;
      width: 2.375rem;
      height: 2.375rem;
    }
  }
}
</style>