<template>
	<!-- <svg :viewBox="viewBox" :width="width" :height="height" :fill="computedColor" :stroke="stroke" /> -->
	<inline-svg
		:src="filepath"
		:width="width"
		:height="width"
		:class="{'not-filled': notFilled}"
		:viewBox="vbAsWidth ? `0 0 ${width || 24} ${width || 24}` : viewBox"
		class="svg-icon"
		@click="$emit('click', $event)"
		@loaded="onLoaded"
	/>
	<!-- Чтобы залить иконку другим цветом надо добавить класс и именем цвета из my-tail -->
</template>

<script>

export default {
	props: {
		// Имя файла (без расширения)
		iconName: {
			type: String,
			required: true,
		},
		color: {
			type: String,
			default: '#025FE1',
		},
		onlyStroke: {
			type: Boolean,
			default: false,
		},
		folder: {
			type: String,
			default: 'icons_new',
		},
		// Ширина
		width: {
			type: [Number, String],
			default: '16',
		},
		// Высота
		height: {
			type: [Number, String],
			default: '16',
		},
		primary: {
			type: Boolean,
			default: true,
		},
		// чтобы вместо fill применялся stroke надо для SVG добавить класс not-filled
		// нужно ставить true если иконка портится при заливке
		notFilled: {
			type: Boolean,
			default: false,
		},
		viewBox: {
			type: String,
      default: '0 0 24 24',
    },
		vbAsWidth: { // костыль чтобы задавать view-box через width
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			// 
		}
	},
	computed: {
		// Путь расположения SVG-файла
		filepath() {
			return require(`@/assets/${this.folder}/${this.iconName}.svg`);
			// return this.$store.getters.getIconSVGSafe(this.iconName);
		},
	},
	methods: {
		async setColor() {
			// await this.$nextTick()
			const paths = this.$el.querySelectorAll('path')
			paths.forEach(path => {
				path.removeAttribute('stroke')
				path.removeAttribute('fill')
			})
			if (this.onlyStroke) {
				this.$el.setAttribute('stroke', this.color)
				this.$el.setAttribute('fill', 'none')
			} else this.$el.setAttribute('fill', this.color)
		},
		async onLoaded() {
			await this.setColor()
		},
	},
	async mounted() {
		// if (this.$el.nodeName == '#comment') return
		// await this.$nextTick(this.setColor)
	}
};
</script>

<style>

</style>
