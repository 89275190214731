import Vue from 'vue';
import { SET_NOTIFICATION, SET_NOTIFICATIONS, SET_MESSAGES, ADD_MESSAGE, SOCKET_NEW_MESSAGE } from '@/store/notification/mutations.type';

function compareByDate(a: any, b: any) {
  if (a.dateCreated < b.dateCreated) return 1;
  if (a.dateCreated > b.dateCreated) return -1;
  return 0;
}

/**
 * Запись настроек для одного пользователя - когда он сам записывает их
 */
export const postNotification = (context: any, notificationInfo: any) => {
  return Vue.axios
    .post(`api/notification`, notificationInfo).then((response: any) => {
      console.log(response.data);
      context.commit(SET_NOTIFICATION, response.data.result);

    }).catch(err => {
      console.log(err);
    });
};

/**
 * Запись настроек для одного пользователя - когда суперадмин правит
 */
export const postNotifications = async (context: any, notificationInfo: any) => {
  try {
    await Vue.axios.post(`api/notification`, notificationInfo)
    const notifs = await Vue.axios.get(`api/notification/${notificationInfo.client_id}/all`)
    context.commit(SET_NOTIFICATIONS, notifs.data); // нет result!
  } catch (error) {
    console.log('postNotifications error:',error); 
  }
};

export const getNotifications = (context: any, client_id: any) => {
  return Vue.axios
    .get(`api/notification/${client_id}/all`)
    .then((response: any) => {
      context.commit(SET_NOTIFICATIONS, response.data);
    });
};

export const getNotification = (context: any, userId: any) => {
  return Vue.axios
    .get(`api/notification/${userId}`)
    .then((response: any) => {
      context.commit(SET_NOTIFICATION, response.data.result);
    });
};

// сохранение сообщения в базу
export const createMessage = (context: any, msg: any) => {
  Vue.axios.post('api/message', msg).then(resp => {
    context.commit(ADD_MESSAGE, resp.data.result)
  })
}

// TODO: пока без userId, надо сделать несколько вариантов получения сообщений
export const getMessages = (context: any, params: any) => {
  return Vue.axios
    .get(`api/message`, { params })
    .then((response: any) => {
      context.commit(SET_MESSAGES, response.data.result.sort(compareByDate));
    });
};

export const newSocketMessage = (context: any, socket_message: any) => {
  context.commit(SOCKET_NEW_MESSAGE, socket_message)
}
// пока непонятно надо ли будет это вообще
// export const markMessagesAsRead = (context: any, msg_id: string) => {
//     return Vue.axios
//         .put('/api/message/read/' + msg_id)
//         .then(res => {
//             if (msg_id == '')
//                 context.commit(MARK_MESSAGE_READ, res.data.resuls)
//             else
//                 context.commit(SET_MESSAGES, res.data.resuls)
//         })
// }

export const sendEmailNotifications = (context: any, notif_data: any) => {
  return Vue.axios
    .put(`api/notification`, notif_data)
    .then((response: any) => {
      console.log(`Notif. service: Emails were sent, ${response.data.result}`);
      //context.commit(SET_SENDING_RESULT, response.data.result.sort(compareByDate));
    }).catch((e: any) => {
      console.log(`Error while e-mail sending: ${e.message}`);
    });
}