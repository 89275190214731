export const SET_ARTICLES = 'SET_ARTICLES'
export const SET_VACANCIES = 'SET_VACANCIES'
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS'
export const SET_TREE = 'SET_TREE'

export const SET_ARTICLE = 'SET_ARTICLE'
export const SET_VACANCY = 'SET_VACANCY'
export const SET_SERVICE_PAGE = 'SET_SERVICE_PAGE'

export const SET_CHILDREN = 'SET_CHILDREN'
export const REMOVE_ARTICLE = 'REMOVE_ARTICLE';
export const REMOVE_VACANCY = 'REMOVE_VACANCY';
export const CHANGE_ARTICLES_ORDER = 'CHANGE_ARTICLES_ORDER';

export const ARTICLE_ADD_FILES = 'ARTICLE_ADD_FILES'
export const ARTICLE_REMOVE_FILES = 'ARTICLE_REMOVE_FILES'

export const SET_UNUSED_FILES = 'SET_UNUSED_FILES'
export const SET_VIEWED = 'SET_VIEWED'
export const SET_RIGHTS = 'SET_RIGHTS'