import * as Service from "./service"
import * as Actions from "./actions.type"
import * as Mutations from "./mutations.type"

import { Tag } from './types';

interface State {
  list: Tag[], // список тегов текущего раздела
  item: Tag | null,  // активный тег (для редактирования)
}


// STORE 
const store: State = {
  list: [], // единый реквизит для списка объектов из общего модуля CRUD (common)
  item: null, // аналогично для активного объекта
}

const actions = {
  [Actions.GET_TAGS]: Service.getTags,
  [Actions.CREATE_TAG]: Service.createTag,
  [Actions.SAVE_TAG]: Service.saveTag,
  [Actions.ATTACH_TAG]: Service.attachTag,
  [Actions.DETACH_TAG]: Service.detachTag,
  [Actions.DELETE_TAG]: Service.deleteTag,
}

const mutations = {
  [Mutations.SET_TAGS](state: State, data: Tag[]) {
    state.list = data
  },
  [Mutations.SET_TAG](state: State, data: Tag) {
    state.item = data
  },
}

export default {
  namespaced: true,
  state: store,
  actions,
  mutations,
};