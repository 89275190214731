import Vue from 'vue';
import { AxiosError, AxiosResponse } from 'axios';

import store from "@/store";

import { SET_TRACK_SCENARIO, SET_TRACK_USERS, ADD_TRACK_USER, SET_TRACK_STEP, SET_TRACK } from "./mutations.type"
import { SET_ITEM, SET_ITEMS } from "@/store/common/mutations.type"

import { AddUserReq, EditUserReq, ChangeStageReq, AddStepReq, ChangeStepReq, TrackScenario, AdaptationSettings, Step, AdaptationTrack, DeleteStageReq } from './types'
import { headers } from '../user/user.service';
// import { UploadResponse } from '../common/types'

const config = {
  onUploadProgress: function(progressEvent:any) {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    store.state.common.progress = percentCompleted
  }
}

// Добавление задачи в панель доски
export const addUserToTrack = async ({commit}: any, data: AddUserReq) => { 
  const response:AxiosResponse = await Vue.axios.post(`api/adaptation/add_user`, data)
  const { result } = response.data
  // изменение текущего трека
  commit(ADD_TRACK_USER, result.new_user)
}

export const addStage = async ({commit}: any, data: ChangeStageReq) => {
  const response:AxiosResponse<TrackScenario> = await Vue.axios.post(`api/adaptation/track/${data.track}/stage`, data)
  // изменение текущего трека
  commit(SET_TRACK_SCENARIO, response.data)
}

export const addStep = async ({commit}: any, data: AddStepReq) => {
  const response:AxiosResponse<TrackScenario> = await Vue.axios.post(`api/adaptation/track/${data.track}/step`, data)
  // изменение текущего трека
  commit(SET_TRACK_SCENARIO, response.data)
}

export const editStep = async ({commit}: any, data: ChangeStepReq) => {
  const response:AxiosResponse<TrackScenario> = await Vue.axios.put(`api/adaptation/track/${data.track}/step`, data)
  // изменение только текущего шага, чтобы не передавать весь сценарий
  commit(SET_TRACK_STEP, response.data)
}

export const editStage = async ({commit}: any, data: ChangeStageReq) => {
  const response:AxiosResponse<TrackScenario> = await Vue.axios.put(`api/adaptation/track/${data.track}/stage`, data)
  // изменение только текущего шага, чтобы не передавать весь сценарий
  commit(SET_TRACK_SCENARIO, response.data)
}

export const delStep = async ({commit}: any, data: ChangeStepReq) => {
  const response:AxiosResponse<TrackScenario> = await Vue.axios.delete(`api/adaptation/track/${data.track}/step`, {data})
  // изменение текущего трека
  commit(SET_TRACK_SCENARIO, response.data)
}

// удаление этапа сценария
export const delStage = async ({commit}: any, data: DeleteStageReq): Promise<void> => {
  const response:AxiosResponse = await Vue.axios.delete(`api/adaptation/track/${data.track}/stage`, {data})
  // изменение текущего трека
  commit(SET_TRACK_SCENARIO, response.data)
}

export const getTrackMembers = async ({commit, rootState}: any, track_id: string) => {
  const response:AxiosResponse = await Vue.axios.get(`api/adaptation/track/${track_id}/members`)
  commit(SET_TRACK_USERS, response.data)
}

// Получение новичков по треку адаптации, у которых указанный сотрудник исполняет указанную роль
// user_id - код ментора или руководителя, который прописан у новичка в поле mentor или chief
// role - определяет в каком поле искать этот user_id
export const getAdaptations = async ({commit, rootState}: any, data: {user_id: string, client_id: string}) => { 
  const response:AxiosResponse = await Vue.axios.get(`api/adaptation/list_for/${data.user_id}`, headers(data))
  // список адаптаций новичков
  commit('common/'+SET_ITEM, { 
    state: rootState.adaptation,
    field: 'users_on_adaptation',
    data: response.data.tracks
  }, {root: true})
}

// удаление всей адаптации
export const delAdaptation = async ({commit}: any, data: {track: string, user_track: string}) => {
  const response:AxiosResponse = await Vue.axios.delete(`api/adaptation/${data.track}/${data.user_track}`)
  // список адаптаций новичков
  commit(SET_TRACK_USERS, response.data)
}

// Получение своего трека новичком
// user_id - ищется в таблице по полю empl как свой сценарий
export const getUserTrack = async ({commit, rootState}: any, user_id: string) => { 
  const response:AxiosResponse = await Vue.axios.get(`api/adaptation/on_track/${user_id}`)
  // Записываем состояние прохождения сотрудником
  commit('common/'+SET_ITEM, {
    state: rootState.adaptation,
    field: 'user_activity',
    data: response.data.user_progress
  }, {root: true})
  // И сам трек для сравнения с прогрессом
  commit(SET_TRACK, response.data.track)
}


type GetTrackAsViewer = {
  adapt_id: string, // Код адаптации
  // user_id: string,   // Запрашивающий, для проверки что он имеет право смотреть
  // track: string,     // Адаптация
  role: string       // роль запрашивающего
}
// получение трека новичка или своего для ментора или другого наблюдателя - должна быть привязка к адаптации и новичку
// Руководитель и ментор могут курировать разных сотрудников на разных видах адаптации
export const getUserTrackAsViewer = async ({commit, rootState}: any, data: GetTrackAsViewer) => { 
  const response:AxiosResponse = await Vue.axios.get(`api/adaptation/on/${data.adapt_id}/as/${data.role}`)
  commit('common/'+SET_ITEM, {
    state: rootState.adaptation,
    field: 'user_activity',
    data: response.data.user_progress
  }, {root: true})
  // И сам трек для сравнения с прогрессом
  if (response.data.track) {
    commit('common/'+SET_ITEM, { 
      state: rootState.adaptation,
      data: response.data.track
    }, {root: true})
  }
}

export const finishStep = async ({commit, rootState}: any, data: {user_track: string, step_id: string}): Promise<void> => {
  const response:AxiosResponse = await Vue.axios.post(`api/adaptation/${data.user_track}/finish/${data.step_id}`)
  // обновляем активность на треке сотрудника
  commit('common/'+SET_ITEM, { 
    state: rootState.adaptation,
    field: 'user_activity',
    data: response.data.user_progress
  }, {root: true})
}

// откат шага, отменяя выполнение задачи предыдущего шага и удаляя текущую 
export const unfinishStep = async ({commit, rootState}: any, user_track: string): Promise<void> => {
  const response:AxiosResponse = await Vue.axios.post(`api/adaptation/${user_track}/unfinish`)
  // обновляем активность на треке сотрудника
  commit('common/'+SET_ITEM, { 
    state: rootState.adaptation,
    field: 'user_activity',
    data: response.data.user_progress
  }, {root: true})
}

// восстановление шага независимо от других
export const resumeStep = async ({commit, rootState}: any, data: {track_id: string, step_id: string}): Promise<void> => {
  const response:AxiosResponse = await Vue.axios.post(`api/adaptation/${data.track_id}/resume/${data.step_id}`)
  // обновляем активность на треке сотрудника
  commit('common/'+SET_ITEM, { 
    state: rootState.adaptation,
    field: 'user_activity',
    data: response.data.user_progress
  }, {root: true})
}

// удаление шага в активной пользовательской адаптации
export const delUserStep = async ({commit, rootState}: any, data: {track_id: string, step_id: string}): Promise<void> => {
  const response:AxiosResponse = await Vue.axios.post(`api/adaptation/${data.track_id}/del/${data.step_id}`)
  // обновляем активность на треке сотрудника
  commit('common/'+SET_ITEM, { 
    state: rootState.adaptation,
    field: 'user_activity',
    data: response.data.user_progress
  }, {root: true})
}

// получение настроек модуля
export const getSettings = async ({commit, rootState}: any, client_id: string): Promise<void> => {
  const response:AxiosResponse = await Vue.axios.get(`api/adaptation/${client_id}/settings`)
  // обновляем активность на треке сотрудника
  commit('common/'+SET_ITEM, { 
    state: rootState.adaptation,
    field: 'adaptationSettings',
    data: response.data
  }, {root: true})
}

// запись настроек модуля адаптации
export const saveSettings = async ({commit, rootState}: any, data: AdaptationSettings): Promise<void> => {
  const response:AxiosResponse = await Vue.axios.post(`api/adaptation/${data.client_id}/settings`, data)
  // обновляем активность на треке сотрудника
  commit('common/'+SET_ITEM, { 
    state: rootState.adaptation,
    field: 'adaptationSettings',
    data: response.data
  }, {root: true})
}

// изменение адаптации сотрудника
export const editUserTrack = async ({commit, rootState}: any, data: EditUserReq): Promise<void> => {
  const response:AxiosResponse = await Vue.axios.put(`api/adaptation/active`, data)
  // обновляем активность на треке сотрудника
  commit('common/'+SET_ITEM, {
    state: rootState.adaptation,
    field: 'user_activity',
    data: response.data.user_progress
  }, {root: true})
}

// дублирование настроек трека в новый
export const copyTrack = async ({commit, rootState}: any, _id: string): Promise<void> => {
  const response:AxiosResponse = await Vue.axios.post(`api/adaptation/copy/${_id}`)
  commit('common/'+SET_ITEMS, { 
    state: rootState.adaptation,
    data: response.data
  }, {root: true})
}

// Удаление трека вместе с адаптациями
export const delTrack = async ({commit, rootState}: any, _id: string): Promise<void | {error: string}> => {
  try {
    const response:AxiosResponse = await Vue.axios.post(`api/adaptation/delete/${_id}`)
    commit('common/'+SET_ITEMS, { 
      state: rootState.adaptation,
      data: response.data
    }, {root: true})
  } catch (error) {
    const err = error as AxiosError
    return {error: `${err}`} 
  }
}