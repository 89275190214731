export const CREATE_PROJECT = 'CREATE_PROJECT';
export const GET_PROJECTS = 'GET_PROJECTS';

export const GET_CURRENT_PROJECT = 'GET_CURRENT_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const EDIT_PROJECT = 'EDIT_PROJECT';

export const CREATE_PROJECT_PARTICIPANT = 'CREATE_PROJECT_PARTICIPANT';



