import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import ru from './ru'
import ua from './ua'

Vue.use(VueI18n)

function plur_slavic(choice, choicesLength) {
  // this === VueI18n instance, so the locale property also exists here

  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;

  if (choicesLength < 4) {
    return (!teen && endsWithOne) ? 1 : 2;
  }
  if (!teen && endsWithOne) {
    return 1;
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return (choicesLength < 4) ? 2 : 3;
}

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'ru',
  messages: {
    ru, en, ua,
  },
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    'ru': plur_slavic,
    'ua': plur_slavic,
  }
})

const translate = (key: string) => {
  if (!key) {
    return '';
  }
  return i18n.t(key);
};

export { i18n, translate}; //export above method