<template>
  <div style="position: relative" :key="key">
    <a-tooltip :title="title">
      <a-avatar
        v-if="person"
        :src="getSrc" 
        :size="size"
        style="cursor: pointer; flex-shrink: 0"
        @click="() => click ? click() : goTo(person._id)">
      </a-avatar>
      <img v-else :src="getSystemAvatar" :width="size" :height="size" />
    </a-tooltip>
    <a-icon v-if="showIcon" :type="icon" class="icon-admin" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "AvatarClick",
  props: {
    person: Object,
    icon: String,
    showIcon: Boolean,
    tooltip: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 32,
    },
    click: Function, // переопределение клика
  },
  data() {
    return {
      no_img: require("@/assets/no_image.png"),
      key: this?.person?._id,
    }
  },
  computed: {
    ...mapState({
      needReloadAvatar: s => s.auth.needReloadAvatar, 
    }),
    getSystemAvatar() {
      const vendor = this.$store.state.common.vendor
      return vendor == 'yandex' 
        ? require('@/assets/Icons/connectableIcon_32.svg') 
        : require('@/assets/Icons/intranetableIcon_32.svg')
    },
    title() {
      const { firstName, lastName, fullName, name } = this.person || {}
      return this.tooltip 
        || (this.person ? fullName || name || `${firstName} ${lastName}` : '')
    },
    getSrc() {
      const { pics, googleImage, logo } = this.person
      const url = (pics && pics.length) ? pics[0].mini : (googleImage || logo)
      return url || this.no_img
    },
  },
  methods: {
    goTo(_id) {
			let routeData = this.$router.resolve({name: 'profile', params: {_id}});
			window.open(routeData.href, '_blank');
		},
  },

  watch: {
    // перерисовка авы для конкретного юзера, когда появляется его id как значение этого поля
    needReloadAvatar(key) {
      if (key) {
        const [uid, time] = key.split('.')
        if (this.person._id == uid) this.key = key
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>