import Vue from 'vue';
import {
  ADD_GROUP,
  REMOVE_GROUP,
  SET_CURRENT_GROUP,
  SET_GROUPS, SET_INVITE,
  SET_PARTICIPANTS_REQUEST,
  UPDATE_GROUP,
  SET_REQUESTS,
  SET_INVITES,
  ADD_INVITE,
  DELETE_INVITE,
  SET_GROUPS_USER_CAN_READ
} from '@/store/group/mutations.type';
import { GET_CURRENT_GROUP } from '@/store/group/actions.type';
import { AxiosResponse } from 'axios';

export const createGroup = (context: any, group: any) => {
  return Vue.axios
    .post(`api/group/`, group)
    .then((response: any) => {
      context.commit(ADD_GROUP, response.data.result);
      return response.data.result._id
    })
};

export const getGroups = (context: any, userId: any) => {
  return Vue.axios
    .get(`api/group/byUser/${userId}`)
    .then((response: any) => {
      context.commit(SET_GROUPS, response.data.result);
    });
};

export const getGroupsByClient = (context: any, workspace: any) => {
  return Vue.axios
    .get(`api/group/byClient/${workspace}`)
    .then((response: any) => {
      context.commit(SET_GROUPS, response.data.result);
    });
};
export const getCurrentGroup = (context: any, groupId: any) => {
  return Vue.axios
    .get(`api/group/${groupId}`)
    .then((response: any) => {
      if (response.data.status != 404){
        context.commit(UPDATE_GROUP, response.data.result);
        context.commit(SET_CURRENT_GROUP, response.data.result);
        return response.data.result;
      }
      else {
        context.commit(SET_CURRENT_GROUP, null);
        return 404
      }
    });
};

export const deleteGroup = (context: any, groupId: any) => {
  return Vue.axios
    .delete(`api/group/${groupId}`)
    .then((response: any) => {
      context.commit(REMOVE_GROUP, response.data.result);
    });
};

export const editGroup = (context: any, group: any) => {
  return Vue.axios
    .put(`api/group/${group._id}`, group)
    .then((response: any) => {
      context.commit(UPDATE_GROUP, response.data.result);
    });
};

type AddToGroupResult = {
  status: number,
  result: string
}

export const createParticipant = async (context: any, data: any) => {
  const res:AxiosResponse<AddToGroupResult> = await Vue.axios.post(`api/group/${data.groupId}/participant/${data.userId}`, data)
  context.dispatch(GET_CURRENT_GROUP, data.groupId);
  return res.data
};

export const getParticipantsRequest = (context: any, props: any) => {
  return Vue.axios
    .get(`api/group/${props.groupId}/checkParticipant/${props.participantId}`)
    .then((response: any) => {
      context.commit(SET_PARTICIPANTS_REQUEST, response.data);
    });
};

export const approveParticipantsRequest = async (context: any, d: any) => {
  const response = await Vue.axios
    .post(`api/group/${d.groupId}/participant/${d.userId}/approve`)
  context.dispatch(GET_CURRENT_GROUP, d.groupId);
};

export const rejectParticipantRequest = (context: any, data: any) => {
  return Vue.axios
    .delete(`api/group/${data.groupId}/request/${data.userId}`)
    .then((response: any) => {
      context.dispatch(GET_CURRENT_GROUP, data.groupId);
    });
}

export const deleteParticipant = (context: any, data: any) => {
  return Vue.axios
    .delete(`api/group/${data.groupId}/participant/${data.userId}`)
    .then((response: any) => {
      context.dispatch(GET_CURRENT_GROUP, data.groupId);
    });
};

export const createInvite = (context: any, props: any) => {
  return Vue.axios
    .post(`api/groupInvite`, props)
    .then((response: any) => {
      context.commit(ADD_INVITE, response.data.result);
    });
};

export const getInvite = (context: any, id: string) => {
  return Vue.axios
    .get(`api/groupInvite/${id}`)
    .then((response: any) => {
      context.commit(SET_INVITE, response.data.result);
    });
};


export const getUserInvites = (context: any, user_id: string) => {
  return Vue.axios
    .get(`api/group/invites4user/${user_id}`)
    .then((response: any) => {
      context.commit(SET_INVITES, response.data.result);
    });
};

export const getGroupInvites = (context: any, group_id: string) => {
  return Vue.axios
    .get(`api/group/invites/${group_id}`)
    .then((response: any) => {
      context.commit(SET_INVITES, response.data.result);
    });
};


export const approveInvite = (context: any, id: string) => {
  return Vue.axios
    .post(`api/group/approveInvite/${id}`)
    .then(() => {
      context.commit(DELETE_INVITE, id)
    });
};

export const cancelInvite = (context: any, id: string) => {
  return Vue.axios
    .post(`api/group/cancelInvite/${id}`)
    .then(() => {
      context.commit(DELETE_INVITE, id)
    });
};

export const replaceGroupsOwner = (context: any, userId: string) => {
  return Vue.axios
    .post(`api/group/replace_owner/${userId}`);
};

export const changeGroupOwner = (context: any, data: any) => {
  return Vue.axios
    .put(`api/group/${data.groupId}/owner/${data.newOwner}`)
    .then((response: any) => {
      context.dispatch(GET_CURRENT_GROUP, data.groupId);
    });
};

export const getRequests = async (context: any, userId: string) => {
  const response = await Vue.axios.get(`api/group/requests/${userId}`)
  context.commit(SET_REQUESTS, response.data);
  return response.data
}

export const getGroupsThatUserCanRead = async (context: any, userId: string) => {
  const response = await Vue.axios.get(`api/group/can_read_by/${userId}`)
  context.commit(SET_GROUPS_USER_CAN_READ, response.data);
  return response.data
}