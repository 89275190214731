// import actions
import * as Actions from './actions.type'

// import mutations
import * as Mutations from './mutations.type'

// import service functions
import * as Service from './mailing.service'

import {Mailing} from './types'

interface State {
  mailings: Mailing[] | null,
  mailing: Mailing | null
}

const store: State = {
  mailings: null,
  mailing: null,
}

const getters = {
  mailings(state: State) {
    return state.mailings;
  },
  mailing(state: State) {
    return state.mailing;
  },
}

const mutations = {
  [Mutations.ADD_MAILING](state: State, data: Mailing) {
    state.mailings?.push(data);
  },
  [Mutations.REMOVE_MAILING](state: State, id: string) {
    state.mailings = state.mailings?.filter(m => m._id != id) || null
  },
  [Mutations.SET_MAILING](state: State, data: Mailing) {
    state.mailing = data
  },
  [Mutations.REPLACE_MAILING](state: State, data: Mailing) {
    state.mailings = state.mailings?.map(m => m._id == data._id ? data : m) || []
  },
  [Mutations.SET_MAILINGS](state: State, data: Mailing[]) {
    state.mailings = data
  }
}

const actions = {
  [Actions.GET_MAILINGS]: Service.getMailings,
  [Actions.GET_MAILING]: Service.getMailing,
  [Actions.EDIT_MAILING]: Service.saveMailing,
  [Actions.CREATE_MAILING]: Service.createMailing,
  [Actions.DELETE_MAILING]: Service.deleteMailing,
}

export default {
  state: store,
  getters,
  actions,
  mutations
};