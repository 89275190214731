var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
  'f-align-center': !_vm.top && !_vm.between,
  'f-between-center': _vm.between,
  'f-align-bottom': _vm.bottom,
  'f-align-top': _vm.top,
  'f-centered': _vm.centered,
  'flex-wrap': _vm.wrap,
  'w-100': _vm.full,
  [`gap${_vm.gap}`]: true,
},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }