import { LinksBlock } from './types'


import * as Service from "./service"
import * as Actions from "./actions.type"
import * as Mutations from "./mutations.type"

interface State {
  blocks: LinksBlock[],
  block: LinksBlock | null,
}

// STORE 
const store: State = {
  blocks: [], // единый реквизит для списка объектов из общего модуля CRUD (common)
  block: null, // аналогично для активного объекта
}


const actions = {
  [Actions.GET_BLOCKS]: Service.getBlocks,
  [Actions.GET_BLOCK]: Service.getBlock,
  [Actions.TOGGLE_BLOCK]: Service.toggleBlock,
  [Actions.ADD_LINK]: Service.addLink,
  [Actions.EDIT_LINK]: Service.editLink,
  [Actions.DEL_LINK]: Service.delLink,
}

const mutations = {
  [Mutations.SET_BLOCKS](state: State, data: LinksBlock[]) {
    state.blocks = data
  },
  [Mutations.TOGGLE_BLOCK](state: State, data: LinksBlock) {
    if (!state.blocks.find(i => i.module == data.module)) state.blocks.push(data)
    else state.blocks = state.blocks.map(block => block.module == data.module ? data : block);
  },
  [Mutations.SET_BLOCK](state: State, data: LinksBlock) {
    state.block = data
  },
}

export default {
  namespaced: true,
  state: store,
  actions,
  mutations,
};