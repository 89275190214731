// только специфичные действия для оптимальности, 
// не покрывающиеся CRUD из общего модуля
export const GET_TASK = 'GET_TASK'; // получение задачи (при открытии окна)
export const NEW_TASK = 'NEW_TASK'; // добавление задачи с указанием колонки и доски
export const CREATE_TASK = 'CREATE_TASK'; // создание задачи без привязки к трекерам, а для других разделов
export const EDIT_TASK = 'EDIT_TASK'; // изменение задачи или ее части
export const DEL_TASK = 'DEL_TASK'; // удаление задачи

export const DEL_LANE = 'DEL_LANE'; // удаление колонки с ее задачами
export const MOVE_TASK = 'MOVE_TASK'; // перемещение задачами между панелями
export const DESK_FINISH = 'DESK_FINISH'; // завершение доски и всех задач в ней

