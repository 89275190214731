<template>
  <div class="app-header w-100">
    <div class="header" v-if="showHeaderImage && currentClient && $mq == 'desktop'">
      <div
        class="header-image"
        :style="{ backgroundImage: `url('${head_img}')` }"
      >
        <!-- <a-icon type="close" @click="closeImage" /> -->
      </div>
    </div>
    <LoginBar />
  </div>
</template>

<script>
import { SET_SHOW_IMAGE_HEADER } from "@/store/shower/mutations.type";

import { mapActions, mapGetters } from "vuex";

import LoginBar from "@/components/common/blocks/LoginBar";

export default {
  name: "AppHeader",
  components: {
    LoginBar,
  },
  data() {
    return {
      current: 1,
      defaultImg:
        "https://smallbiztrends.com/wp-content/uploads/2018/03/shutterstock_705804559.jpg",
    };
  },
  computed: {
    ...mapGetters(['userData',"showHeaderImage", "currentClient", "userIsSuperAdmin"]),
    head_img() {
      return this.currentClient.head || this.defaultImg;
    },
  },
  methods: {
    closeImage() {
      this.$store.commit(SET_SHOW_IMAGE_HEADER, false);
      document.body.classList.add("is-hide-img-header");
    }
  },
  created() {
    // console.log('created header:');
    
  }
};
</script>

<style lang="scss">
DIV.app-header {
  // height: 50px;
  @media (min-width: 1281px) {
    height: 210px;
  }
  // @media (max-width: 420px) {
  //   height: 100px;
  // }
  padding: 0;
  // line-height: 40px;
}
.header {
  &-image {
    width: 100%;
    height: 10rem;
    //background-image: url(https://smallbiztrends.com/wp-content/uploads/2018/03/shutterstock_705804559.jpg);
    text-align: right;

    .anticon {
      position: relative;
      top: 10px;
      right: 10px;
      color: white !important;
      background: #3b86ff;
      border-radius: 20rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>