export const SET_POSTS = 'SET_POSTS';
export const SET_POSTS_OF_FOLLOWS = 'SET_POSTS_OF_FOLLOWS';
export const SET_POSTS_OF_GROUPS = 'SET_POSTS_OF_GROUPS';
export const SET_POST = 'SET_POST';
export const ADD_POST = 'ADD_POST';

export const SET_COMPANY_FEED = 'SET_COMPANY_FEED'
export const SET_USER_FEED = 'SET_USER_FEED'
export const SET_NOTIFICATIONS_FEED = 'SET_NOTIFICATIONS_FEED'

export const ADD_COMMENT_FOR_POST = 'ADD_COMMENT_FOR_POST';
export const ADD_LIKE_FOR_POST = 'ADD_LIKE_FOR_POST';
export const ADD_LIKE_FOR_ANSWER = 'ADD_LIKE_FOR_ANSWER';
export const ADD_LIKE_FOR_COMMENT = 'ADD_LIKE_FOR_COMMENT';
export const ADD_ANSWER_FOR_COMMENT = 'ADD_ANSWER_FOR_COMMENT';
export const REMOVE_POST = 'REMOVE_POST';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const REMOVE_ANSWER = 'REMOVE_ANSWER';

export const SET_POST_VIEWED = 'SET_POST_VIEWED';
export const SET_POST_TEXT = 'SET_POST_TEXT';

export const CHANGE_POST = 'CHANGE_POST';
export const CHANGE_COMMENT = 'CHANGE_COMMENT';
export const CHANGE_ANSWER = 'CHANGE_ANSWER';

// комментарии, которые надо показывать отдельно, в отдельных лентах (или ленте)
export const SET_COMMENTS_FEED = 'SET_COMMENTS_FEED'
export const SET_MENTIONS_FEED = 'SET_MENTIONS_FEED'

export const SET_POST_FOR_EDITING = 'SET_POST_FOR_EDITING';
export const SET_COMMENT_FOR_EDITING = 'SET_COMMENT_FOR_EDITING';
export const SET_EDIT_POST_VISIBLE = 'SET_EDIT_POST_VISIBLE';
export const SET_EDIT_COMMENT_VISIBLE = 'SET_EDIT_COMMENT_VISIBLE';

export const TOGGLE_EMOJI_PICKER = 'TOGGLE_EMOJI_PICKER'
export const SET_EMOJI = 'SET_EMOJI'